<div class="content">
    <button class="btn btn-light btn-back" (click)="back()">Back</button>

    <div class="form-groups-column">
        <div class="form-group">
            <label>Test Name</label>
            <input type="text" class="form-control" [(ngModel)]="testName" />
        </div>

        <div class="form-group">
            <label>Test Type</label>
            <app-plate-size-picker [plateSizeId]="plateSizeId" (onPlateSizeSet)="setPlateSize($event)">
            </app-plate-size-picker>
        </div>

        <div class="form-group">
            <label>Plate Size</label>
            <app-plate-load-size-picker [plateLoadSizeId]="plateLoadSizeId"
                (onLoadPlateSizeSet)="setLoadPlateSize($event)"></app-plate-load-size-picker>
        </div>

        <div class="form-group">
            <label>BS Standard</label>
            <input type="text" class="form-control" [(ngModel)]="bsStandard" />
        </div>

        <div class="form-group">
            <label>Minute Interval</label>
            <input type="number" class="form-control" [(ngModel)]="minuteIncrement" />
        </div>

        <div class="form-group">
            <label>Test Specification</label>
            <input type="text" class="form-control" [(ngModel)]="testSpecification" />
        </div>

        <div class="form-group">
            <label>Plate Load</label>
            <app-plate-load-picker [plateLoadIncrement]="plateLoadIncrement" (onPlateLoadSet)="onPlateLoadSet($event)">
            </app-plate-load-picker>
        </div>

        <!-- <hr /> -->

        <div class="form-group">
            <label>Plate Load</label>
            <div class="test-load-container">
                <div class="test-input-container">
                    <input type="number" class="form-control" [(ngModel)]="currentLoad" />
                </div>
                <div class="text-center test-add-btn-container">
                    <button class="btn btn-success btn-save full-height" (click)="add()">Add</button>
                </div>
            </div>
        </div>

        <table *ngIf="loads.length > 0" class="buffer-bottom table">
            <thead>
                <tr>
                    <th>Load</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let load of loads">
                    <td>{{ load }}</td>
                    <td><button class="add-btn" (click)="removeLoad(load)">Remove</button></td>
                </tr>
            </tbody>
        </table>
    </div>

    <button class="btn btn-success btn-save buffer-top" (click)="save()">Save</button>
</div>