import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import { IssueService } from 'src/app/services/issue.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-problematic-jobs',
  templateUrl: './problematic-jobs.component.html',
  styleUrls: ['./problematic-jobs.component.scss']
})
export class ProblematicJobsComponent implements OnInit {

  rows = [];
  displayDialog: boolean = false;
  jobTests = [];

  arrivalTime = '';
  departureTime ='';
  filter = {
    project: "",
    account: "",
    purchaseOrder: ""
  }

  constructor(private jobService: JobService, private issueService: IssueService, private reportableService: ReportableService) {
  }

  ngOnInit(): void {
    this.loadTable();

  }

  preview(job): void {
    this.reportableService.getPlateBearingByJob(job.id).then((data) => {
      importedSaveAs(data, "PlateBearings.pdf");
    });
  }

  issue(job): void {
    this.issueService.issueAgain(job.id, job.selectedJobTestForInvoicingId).then((result) => {
      this.loadTable();
    });
  }

  assignTest(job): void {

    this.jobService.getJobTests(job).then((data) => {
      this.jobTests = data;

      if (this.jobTests.length > 1) {
        this.arrivalTime = job.arrivalTime;
        this.departureTime = job.departureTime;
        this.displayDialog = true;
      } else {
        // There's only one test assigned so use this
        let jobTest = this.jobTests[0];
        this.assignTestToJobForInvoicing(jobTest);
      }
    });
  }

  setTest() {
    if (this.jobTests.filter(t => t.selectedForInvoicing == true).length == 0) {
      alert("Please select a test to be used for invoicing purposes");
      return;
    }

    if (this.jobTests.filter(t => t.selectedForInvoicing == true).length > 1) {
      alert("Please select one test to be used for invoicing purposes");
      return;
    }

    let jobTest = this.jobTests.find(u => u.selectedForInvoicing == true);
    this.assignTestToJobForInvoicing(jobTest);
  }

  assignTestToJobForInvoicing(jobTest) {
    this.jobService.assignTestForInvoicing(jobTest.id, jobTest.chosenHours).then((data) => {
      if (this.displayDialog) {
        this.displayDialog = false;
      }
      this.loadTable();
    });
  }

  cancel() {
    this.displayDialog = false;
  }

  loadTable(): void {
    this.jobService.getProblematicTable(this.filter.project, this.filter.account, this.filter.purchaseOrder, true, true).then((jobs) => {
      this.rows = jobs;
      console.log(this.rows);
    });
  }

  generateSamples(job): void {
    this.issueService.generateSamples(job.id).then((result) => {
      console.log(result);
    });
  }

}
