import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from 'src/app/services/job.service';
import * as moment from 'moment';
import { validate } from 'json-schema';
import { weather } from '../../../../models/weather';
import { TechnicianService } from '../../../../services/technician.service';
import { technician } from '../../../../models/technician';

@Component({
  selector: 'app-scheduler-manage',
  templateUrl: './scheduler-manage.component.html',
  styleUrls: ['./scheduler-manage.component.scss']
})
export class SchedulerManageComponent implements OnInit {

  scheduledDate;
  arrivalTime;
  departureTime;

  job;
  technician;
  tempTechnician: technician;
  notes: string;
  pour_Element: string;
  weatherId;
  compactionId;
  po: string;
  weather: weather[];
  compaction: [];
  technicians: [];
  reportTypes: any[];
  selectedTechnicians: technician[];
  reissue: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private jobService: JobService, private technicianService: TechnicianService) {
    jobService.getWeather().then((data) => {
      this.weather = data;
    });

    jobService.getCompactionMethods().then((data) => {
      this.compaction = data;
    });
    technicianService.getTable().then((technicians) => {
      this.technicians = technicians;

      if (this.technician != null)
        this.setTechnician(this.technician);
    });
  }

  ngOnInit(): void {
    this.selectedTechnicians = [];
    this.route.paramMap.subscribe((paramsMap) => {
      ``
      var id = paramsMap.get("id");
      if (id != null) {
        this.jobService.get(id).then((job) => {
          this.job = job;
          console.log("job");
          console.log(job);
          console.log(job.reportTypeId);
         
          this.reportTypes = [];
          for (var i = 0; i < job.jobTestTypes.length; i++) {
            var type = job.jobTestTypes[i];
            this.reportTypes.push(type.reportType);
          }

          this.route.queryParamMap.subscribe((queryParams) => {
            console.log(queryParams);
            this.reportTypeId = job.reportTypeId;
            this.reportTypeChanged();
            if (queryParams.get("reissue") == null) {
              this.scheduledDate = job.scheduledDate;
              this.arrivalTime = job.arrivalTime;
              this.departureTime = job.departureTime;
              this.notes = job.notes;
              
              // this.po = job.purchaseOrderNo;
            } else {
              this.job.technicianId = null;
              this.reissue = true;
              // this.po = job.purchaseOrderNo;
            }
          });


        });
      }
    });
  }
  reportTypeId: number = 0;
  reportTypeChanged() {
    for (var i = 0; i < this.job.jobTestTypes.length; i++) {
      var type = this.job.jobTestTypes[i];
      if (this.reportTypeId == type.reportTypeId)
          this.po = type.poNumber;
      }
  }
  validate() {
    if (this.scheduledDate == null) {
      alert("Enter scheduled date");
      return false;
    }

    if (this.technician == null) {
      alert("Select technician");
      return false;
    }

    if (this.job.reportTypes.includes(2)) { //Concrete Pour
      //if (this.pour_Element == null) {
      //  alert("Enter Pour Element");
      //  return false;
      //}
      //if (this.weatherId == null) {
      //  alert("Enter Weather Conditions");
      //  return false;
      //}
      //if (this.compactionId == null) {
      //  alert("Enter Compaction Method");
      //  return false;
      //}
    }

    return true;
  }

  async save() {
    if (this.job.reportTypes.includes(2)) {
      if (this.selectedTechnicians.length > 0)
        this.technician = this.selectedTechnicians[0];
    }
    if (!this.validate()) {
      return;
    }

    this.job.scheduledDate = moment(this.scheduledDate).format("YYYY-MM-DD");
    this.job.arrivalTime = this.arrivalTime;
    this.job.departureTime = this.departureTime;
    this.job.technicianId = this.technician.id;
    this.job.notes = this.notes;
    this.job.purchaseOrderNo = this.po;
    this.job.fromParent = true;
    //this.job.pour_Element = this.pour_Element;
    this.job.reportTypeId = this.reportTypeId;

    // for (var i = 0; i < this.job.jobTestTypes.length; i++) {
    //   var type = this.job.jobTestTypes[i];
    //     if(type.reportTypeId == this.reportTypeId)
    //       type.poNumber = this.po;
    // }

    //this.job.compactionId = this.compactionId;
    this.job.selectedTechnicians = this.selectedTechnicians;

    if (this.reissue) {
      await this.jobService.duplicateJob(this.job)
    } else {
      await this.jobService.updateJob(this.job)
    }


    this.router.navigate(['scheduler']);
  }

  setTechnician(technician) {
    this.technician = technician;
  }
  setTempTechnician(technician) {
    this.tempTechnician = technician;
  }
  addTechnician() {
    var exists: boolean = false;
    for (var i = 0; i < this.selectedTechnicians.length; i++) {
      var tech = this.selectedTechnicians[i];
      if (tech.id == this.tempTechnician.id)
        exists = true;
    }
    if (!exists)
      this.selectedTechnicians.push(this.tempTechnician);

    console.log(this.selectedTechnicians);
  }
  removeTechnician(technician) {
    var idx: number = -1;
    for (var i = 0; i < this.selectedTechnicians.length; i++) {
      var tech = this.selectedTechnicians[i];
      if (tech.id == technician.id)
        idx = i;
    }
    if (idx >= 0)
      this.selectedTechnicians.splice(idx, 1);
  }
}
