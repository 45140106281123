import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';

@Injectable()
export class JobPlateTestService {
  constructor(private http: HttpClient,private snotifyService: SnotifyService) {
  }

  public getReportTable(project: string, account: string, purchaseOrder: string, startDate: string, endDate: string): Promise<any> {
    return new Promise((resolve, reject) => {

      var params = "";
      params += "?project=" + project;
      params += "&account=" + account;
      params += "&purchaseOrder=" + purchaseOrder;
      params += "&startDate=" + moment(startDate).format("YYYY-MM-DD");
      params += "&endDate=" + moment(endDate).format("YYYY-MM-DD");

      console.log(params);

      this.http.get<any>(environment.apiUrl + 'jobplatetest/getReportTable' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public list(jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'jobplatetest/list?jobId=' + jobId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'jobplatetest/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public archive(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'Invoice/ArchiveReport?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public update(plateTest): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put<any>(environment.apiUrl + 'job/updateload', plateTest).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public checkAdminPass(pass:string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/checkPass?pass='+ pass).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        this.snotifyService.error(error.error);
        reject();
      });
    });
  }
}
