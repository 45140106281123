

<div class="btn-row">
  <button class="btn btn-light btn-back buffer-top-left" (click)="back()">Back</button>

  <button type="button" class="btn btn-success buffer-right" style="float: inline-end; margin-right: 1%" (click)="update()">Update</button>
  <!-- <button class="buffer-right add-btn" (click)="recalculate(true)">Recalculate</button>
        <button class="buffer-left add-btn" (click)="update()">Update</button> -->
</div>

<div class="details-container">
  <div class="form-group">
    <label>Technician</label>
    <app-technician-picker (onTechnicianSet)="setTechnician($event)" [technicianId]="inspection.userId">
    </app-technician-picker>
  </div>

  <div class="form-group">
    <label>Remarks</label>
    <input type="text" class="form-control po" [(ngModel)]="inspection.remarks" />
  </div>

  <div class="form-group">
    <label>Reason for Re-issue</label>
    <input type="text" class="form-control po" [(ngModel)]="inspection.reason_For_ReIssue" />
  </div>

</div>


<table style="width: 98%; margin: 1%;">
  <tr style="width: 100% ">
    <th style="text-align: center;border: 1px solid #cccccc;">Position</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Date</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Air Temp<br />[°C]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Concrete<br />[°C]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Delivery #</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Weather Condition</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Compaction Method</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Concrete per Truck<br />[m³]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Total Amount of Concrete<br />[m³]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Plant Used</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Concrete Strength</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Batch Time</th>
    <th style="text-align: center;border: 1px solid #cccccc;">On Site</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Concrete Pouring Start</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Concrete Pouring End</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Cube Set Ref #</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Flow<br />[mm]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Sump<br />[mm]</th>
    <th style="text-align: center;border: 1px solid #cccccc;">Air Void<br />Content<br />(%)</th>
  </tr>
  <ng-container *ngFor="let pour of inspection.concretePours">
    <tr style="width: 100% ">
      <td style="border: 1px solid #cccccc;">{{pour.position}}</td>
      <td style="text-align: center; border: 1px solid #cccccc;"><p-calendar name="pour_Date{{pour.id}}{{pour.position}}" [(ngModel)]="pour.pour_Date" dateFormat="dd/mm/yy"></p-calendar></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" [(ngModel)]="pour.air_Temp" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" [(ngModel)]="pour.concrete_Temp" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><textarea type="text" class="form-control po" [(ngModel)]="pour.delivery_Number"></textarea></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><ng-select [items]="weather" autofocus bindLabel="name" bindValue="weatherId" [virtualScroll]="true" placeholder="Please Select" [(ngModel)]="pour.weatherId" [selectOnTab]="true"></ng-select></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><ng-select [items]="compaction" autofocus bindLabel="name" bindValue="compactionId" [virtualScroll]="true" placeholder="Please Select" [(ngModel)]="pour.compactionId" [selectOnTab]="true"></ng-select></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" (ngModelChange)="amountPouredChanged()" [(ngModel)]="pour.amount_Poured" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" disabled="true" [(ngModel)]="pour.total_Amount_Poured" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><textarea type="text" class="form-control po" [(ngModel)]="pour.plant_Num"></textarea></td>
      <td style="text-align: center; border: 1px solid #cccccc;">
        <app-concrete-strength-picker (onConcreteStrengthSet)="setConcreteStrength(pour,$event)" [concreteStrength]="pour.concreteStrength">
        </app-concrete-strength-picker>
      </td>
      <td style="text-align: center; border: 1px solid #cccccc;"><p-calendar name="batchTime{{pour.id}}{{pour.position}}" showTime="true" [hourFormat]="12" [timeOnly]="true" [stepMinute]="15" [(ngModel)]="pour.batchTime"></p-calendar></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><p-calendar name="onSite{{pour.id}}{{pour.position}}" showTime="true" [hourFormat]="12" [timeOnly]="true" [stepMinute]="15" [(ngModel)]="pour.onSite"></p-calendar></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><p-calendar name="startTime{{pour.id}}{{pour.position}}" showTime="true" [hourFormat]="12" [timeOnly]="true" [stepMinute]="15" [(ngModel)]="pour.startTime"></p-calendar></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><p-calendar name="endTime{{pour.id}}{{pour.position}}" showTime="true" [hourFormat]="12" [timeOnly]="true" [stepMinute]="15" [(ngModel)]="pour.endTime"></p-calendar></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><textarea type="text" class="form-control po" [(ngModel)]="pour.cube_Set_Ref"></textarea></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" [(ngModel)]="pour.flow" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" [(ngModel)]="pour.sump" /></td>
      <td style="text-align: center; border: 1px solid #cccccc;"><input type="number" class="form-control po" [(ngModel)]="pour.void" /></td>
    </tr>
  </ng-container>
</table>
