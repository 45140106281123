<ng-snotify></ng-snotify>

<app-login *ngIf="!isAuthed"></app-login>

<div *ngIf="isAuthed">
  <nav>
    <ul>
      <li>
        <a routerLink="/dashboard" routerLinkActive="active">
          <i class="fas fa-home"></i>
          Dashboard
        </a>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn"><i class="fas fa-home"></i>Reports </button>

          <div class="dropdown-content">
            <a routerLink="/reports/platebearing" routerLinkActive="active">Plate Bearing</a>
            <a routerLink="/reports/concretepour" routerLinkActive="active">Concrete Pour</a>
          </div>
        </div>
      </li>
      <li>
        <a routerLink="/project" routerLinkActive="active">
          <i class="fas fa-project-diagram"></i>
          Project Management
        </a>
      </li>
      <li>
        <a routerLink="/scheduler" routerLinkActive="active">
          <i class="far fa-calendar-alt"></i>
          Scheduler
        </a>
      </li>
      <li>
        <a routerLink="/reviews" routerLinkActive="active">
          <i class="far fa-calendar-alt"></i>
          Review
        </a>
      </li>

      <li>
        <a routerLink="/invoices" routerLinkActive="active">
          <i class="fas fa-file-invoice"></i>
          Invoicing
        </a>
      </li>
      <li>
        <a routerLink="/problematicjobs" routerLinkActive="active">
          <i class="fas fa-history"></i>
          Reassign
        </a>
      </li>
      <!-- <li>
    <a>
      <i class="far fa-question-circle"></i>
      Review
    </a>
  </li>
  <li>
    <a>
      <i class="fas fa-retweet"></i>
      Reissue
    </a>
  </li>
  <li>
    <a>
      <i class="fas fa-history"></i>
      Historical Reports
    </a>
  </li> -->
      <li>
        <a routerLink="/testtypes" routerLinkActive="active">
          <i class="fas fa-users-cog"></i>
          Test Types
        </a>
      </li>
      <li>
        <a routerLink="/technicians" routerLinkActive="active">
          <i class="fas fa-users-cog"></i>
          Technicians
        </a>
      </li>
      <li>
        <a routerLink="/users" routerLinkActive="active">
          <i class="fas fa-users-cog"></i>
          Users
        </a>
      </li>
      <li>
        <a (click)="logout()">
          <i class="fas fa-sign-out-alt"></i>
          Logout
        </a>
      </li>

    </ul>
  </nav>
  <router-outlet></router-outlet>
</div>
