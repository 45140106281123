import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
    currentUser: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
    }

    isAuthed(): boolean {
        var auth = localStorage.getItem("auth");
        if (auth == null || auth == "null") {
            return false;
        }

        if (this.currentUser.value == null)
            this.currentUser.next(JSON.parse(auth));

        return true;
    }

    public login(email, password): Promise<any> {
        return new Promise((resolve, reject) => {
            var dto = {
                email: email,
                password: password
            }
            this.http.post<any>(environment.apiUrl + 'useraccount/login', dto).subscribe(data => {
                console.log(data);
                localStorage.setItem("auth", JSON.stringify(data));
                this.currentUser.next(data);
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public logout() {
        localStorage.setItem("auth", null);
        this.currentUser.next(null);
    }

    // public get(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.http.get<any>('http://localhost:5000/api/account/get').subscribe(data => {
    //             resolve(data);
    //         }, error => {
    //             console.log(error);
    //             reject();
    //         });
    //     });
    // }
}
