import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PlateTestService } from 'src/app/services/plate-test.service';

@Component({
  selector: 'app-plate-load-size-picker',
  templateUrl: './plate-load-size-picker.component.html',
  styleUrls: ['./plate-load-size-picker.component.scss']
})
export class PlateLoadSizePickerComponent implements OnInit {
  @Input() plateLoadSizeId: number;
  @Output() onLoadPlateSizeSet = new EventEmitter<any>();


  plateLoadSizes = [];

  constructor(private plateTestService: PlateTestService) {
  }

  ngOnInit(): void {
    this.plateTestService.getLoadPlateSizes().then((sizes) => {
      this.plateLoadSizes = sizes;
    });
  }

  plateLoadSizeChanged() {
    var loadPlateSize = this.plateLoadSizes.find(a => a.id == this.plateLoadSizeId);
    this.onLoadPlateSizeSet.emit(loadPlateSize);
  }

}
