import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TechnicianService } from 'src/app/services/technician.service';

@Component({
  selector: 'app-technician-picker',
  templateUrl: './technician-picker.component.html',
  styleUrls: ['./technician-picker.component.scss']
})
export class TechnicianPickerComponent implements OnInit {
  @Output() onTechnicianSet = new EventEmitter<any>();

  @Input() technicianId: number;
  technicians = [];

  constructor(private technicianService: TechnicianService) {
  }

  ngOnInit(): void {
   
    this.technicianService.getTable().then((technicians) => {
      this.technicians = technicians;

      if (this.technicianId != null)
        this.technicianChanged();
    });
  }


  technicianChanged() {
    var technician = this.technicians.find(a => a.id == this.technicianId);
    this.onTechnicianSet.emit(technician);
  }

}
