import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectsComponent } from './pages/administration/projects/projects.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { ProjectManageComponent } from './pages/administration/projects/project-manage/project-manage.component';
import { SchedulerComponent } from './pages/administration/scheduler/scheduler.component';
import { SchedulerManageComponent } from './pages/administration/scheduler/scheduler-manage/scheduler-manage.component';
import { TechniciansComponent } from './pages/administration/technicians/technicians.component';
import { TechnicianManageComponent } from './pages/administration/technicians/technician-manage/technician-manage.component';
import { TestTypesComponent } from './pages/administration/test-types/test-types.component';
import { TestTypeManageComponent } from './pages/administration/test-types/test-type-manage/test-type-manage.component';
import { PlateBearingReportsComponent } from './pages/reports/plate-bearing-reports/plate-bearing-reports.component';
import { ConcretePourReportsComponent             } from './pages/reports/concrete-pour-reports/concrete-pour-reports.component';
import { ConcretePourReportsManageReviewComponent } from './pages/reports/concrete-pour-reports/review/concrete-pour-review.component';
import { ReportsManageReviewComponent } from './pages/reports/plate-bearing-reports/review/review.component';
import { WebusersComponent } from './pages/administration/webusers/webusers.component';
import { WebusersManageComponent } from './pages/administration/webusers/webusers-manage/webusers-manage.component';
import { ProblematicJobsComponent } from './pages/administration/problematic-jobs/problematic-jobs.component';
import { ReviewComponent } from './pages/administration/review/review.component';
import { ReviewManageComponent } from './pages/administration/review/review-manage/review-manage.component';
//import { ReviewManageReviewComponent } from './pages/administration/review/review-manage/review/review.component';
import { PlateBearingManageReviewComponent } from './pages/administration/review/review-manage/plate-bearing-review/plate-bearing-review.component';
import { ConcretePourManageReviewComponent } from './pages/administration/review/review-manage/concrete-pour-review/concrete-pour-review.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'project', children: [
      { path: '', component: ProjectsComponent },
      { path: 'manage', component: ProjectManageComponent },
      { path: 'manage/:id', component: ProjectManageComponent }
    ]
  },
  {
    path: 'scheduler', children: [
      { path: '', component: SchedulerComponent },
      { path: 'manage', component: SchedulerManageComponent },
      { path: 'manage/:id', component: SchedulerManageComponent }
    ]
  },
  {
    path: 'testtypes', children: [
      { path: '', component: TestTypesComponent },
      { path: 'manage', component: TestTypeManageComponent },
      { path: 'manage/:id', component: TestTypeManageComponent }
    ]
  },
  {
    path: 'technicians', children: [
      { path: '', component: TechniciansComponent },
      { path: 'manage', component: TechnicianManageComponent },
      { path: 'manage/:id', component: TechnicianManageComponent }
    ]
  },
  {
    path: 'users', children: [
      { path: '', component: WebusersComponent },
      { path: 'manage', component: WebusersManageComponent },
      { path: 'manage/:id', component: WebusersManageComponent }
    ]
  },
  {
    path: 'reviews', children: [
      { path: '', component: ReviewComponent },
      { path: 'manage', component: ReviewManageComponent },
      { path: 'manage/:id', component: ReviewManageComponent },
      { path: 'manage/review/plate-bearing/:id/:jobId', component: PlateBearingManageReviewComponent },
      { path: 'manage/review/concrete-pour/:id/:jobId', component: ConcretePourManageReviewComponent },
    ]
  },

  //{ path: 'reviews/manage/review/plate-bearing/:id/:jobId', component: PlateBearingManageReviewComponent},
  //{ path: 'reviews/manage/review/concrete-pour/:id/:jobId', component: ConcretePourManageReviewComponent},
  //{ path: 'reviews/manage/reviews/plate-bearing/:id/:jobId', component: PlateBearingManageReviewComponent},
  //{ path: 'reviews/manage/reviews/concrete-pour/:id/:jobId', component: ConcretePourManageReviewComponent},
  {
    path: 'problematicjobs', children: [
      { path: '', component: ProblematicJobsComponent },
      { path: 'manage/:id', component: ReviewManageComponent },
      { path: 'manage/review/plate-bearing/:id/:jobId', component: PlateBearingManageReviewComponent },
      { path: 'manage/review/concrete-pour/:id/:jobId', component: ConcretePourManageReviewComponent },    ]
  },
  { path: 'invoices', component: InvoicesComponent },
  {
    path: 'reports/platebearing', children: [
      { path: '', component: PlateBearingReportsComponent },
      { path: 'manage/:id', component: ReportsManageReviewComponent }
    ]
  },
  {
    path: 'reports/concretepour', children: [
      { path: '', component: ConcretePourReportsComponent },
      { path: 'manage/:id', component: ConcretePourReportsManageReviewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
