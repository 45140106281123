import { Component, OnInit, Output, EventEmitter, SimpleChanges, Input } from '@angular/core';
import { QuoteService } from 'src/app/services/quote.service';
import { PlateTestService } from 'src/app/services/plate-test.service';

@Component({
  selector: 'app-plate-size-picker',
  templateUrl: './plate-size-picker.component.html',
  styleUrls: ['./plate-size-picker.component.scss']
})
export class PlateSizePickerComponent implements OnInit {
  @Input() plateSizeId: number;
  @Output() onPlateSizeSet = new EventEmitter<any>();

  //plateSizeId: number;
  plateSizes = [];

  constructor(private plateTestService: PlateTestService) {
  }

  ngOnInit(): void {
    this.plateTestService.getPlateSizes().then((sizes) => {
      this.plateSizes = sizes;      
    });        
  }

  plateSizeChanged() {
    var plateSize = this.plateSizes.find(a => a.id == this.plateSizeId);    
    this.onPlateSizeSet.emit(plateSize);
  }
}
