import { Injectable } from '@angular/core';
import { BasketItem } from './basket-item';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  //We have an observable so that we can subscribe to the results;

  basketItems: BehaviorSubject<Array<BasketItem>>;
  dataSource: Array<BasketItem>;
  company_Id: string;
  location_Id: string;

  constructor(private http: HttpClient,private reportableService: ReportableService) {
    this.dataSource = new Array<BasketItem>();
    this.basketItems = new BehaviorSubject<Array<BasketItem>>(this.dataSource);
    //this.basketItems.next(this.dataSource);
  }

  public add(item: BasketItem) {
    if (this.dataSource.some(x => x.id === item.id))
      return;

    this.dataSource.unshift(item);
    this.basketItems.next(this.dataSource);
  }

  public remove(item: BasketItem) {
    const index: number = this.dataSource.indexOf(item);
    if (index !== -1) {
      this.dataSource.splice(index, 1);
    }
    this.basketItems.next(this.dataSource);
  }

  public getItems() {
    return this.basketItems;
  }

  public getDatasource() {
    return this.dataSource;
  }

  public getItem(id) {
    for(var i = 0;i< this.dataSource.length; i++){
      var item = this.dataSource[i];
      if(item.id == id)
        return item;
    }
  }

  public clear() {
    this.dataSource = new Array<BasketItem>();
    this.basketItems.next(this.dataSource);
  }



  downloadReport() {
    this.reportableService.getBatch(this.dataSource).then((data) => {
      importedSaveAs(data, "Batch Report.pdf");
    })
  }

  public getSummary(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>('api/inspection/PostSummary', this.dataSource).subscribe(data => {
        var blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob)
        console.log(blob);
        this.clear();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getZip(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.reportableService.getBatch(this.dataSource).then((data) => {
        var blob = this.convertBase64toBlob(data, 'application/zip');
        resolve(blob)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getBatch(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.reportableService.getBatch(this.dataSource).then((data) => {
        var blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
