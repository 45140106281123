import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class InvoiceService {
    constructor(private http: HttpClient, private authService: AuthService) {
    }

    public get(poNumber: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'invoice/getreport?poNumber=' + poNumber).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public issue(invoices): Promise<any> {
        console.log(invoices);

        return new Promise((resolve, reject) => {
            var user = this.authService.currentUser.value;
            console.log(user);

            let api = environment.apiUrl + 'invoice/post';

            for (let invoice of invoices) {
                invoice.userProfileId = user.userProfileId;
            }

            this.http.post<any>(api, invoices).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
