import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = "";
  password: string = "";

  invalid: boolean = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  onLogin() {
    this.invalid = false;
    this.authService.login(this.email, this.password).then(() => {

    }, (error) => {
      this.invalid = true;
      console.log("ERROR");
    });
  }
}
