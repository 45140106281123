import { Component, OnInit } from '@angular/core';
import { PlateTestService } from 'src/app/services/plate-test.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test-type-manage',
  templateUrl: './test-type-manage.component.html',
  styleUrls: ['./test-type-manage.component.scss']
})
export class TestTypeManageComponent implements OnInit {

  id: null;
  testName: string = "";
  plateSize;
  plateLoad;
  plateLoadSize;
  bsStandard: string = "";
  minuteIncrement: number = 3;
  testSpecification: string = "";
  loads = [];
  currentLoad: number;

  plateSizeId: string;
  plateLoadSizeId: string;

  plateLoadIncrement: number;

  constructor(private plateTestService: PlateTestService, private router: Router, private route: ActivatedRoute) { }

  ngAfterViewInit()
  {
    console.log("Page init sorted");
    this.route.paramMap.subscribe((paramsMap) => {
      var id = paramsMap.get("id");
      if (id != null) {
        this.plateTestService.get(id).then((plateTest) => {        
          this.plateSizeId = plateTest.plateSizeId;        
        });
      }
    });
  }  

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramsMap) => {
      var id = paramsMap.get("id");
      if (id != null) {
        this.plateTestService.get(id).then(async (plateTest) => {
         // console.log(plateTest);
          this.id = plateTest.id;
          this.testName = plateTest.testTypeName;
         // this.plateSizeId = plateTest.plateSizeId;
          this.plateSize = plateTest.plateSize;
          this.bsStandard = plateTest.bsStandard;
          this.minuteIncrement = plateTest.minuteIncrement;
          this.testSpecification = plateTest.testSpecification;

          this.plateLoadSizeId = plateTest.plateLoadSizeId;
          this.plateLoadSize = plateTest.plateLoadSize;
          this.plateLoadIncrement = plateTest.loadIncrement;

          this.loads = plateTest.plateLoads.map(a => a.load);
          
          // Get the selected plateload and set it
          var allPlateLoads = await this.plateTestService.getPlateLoads();
          var existingLoad = allPlateLoads.find(pl => pl.load == plateTest.loadIncrement);
          this.onPlateLoadSet(existingLoad);
        });
      }
    });
  }

  back() {
    this.router.navigate(['testtypes']);
  }

  setPlateSize(plateSize) {
    this.plateSize = plateSize;    
  }

  setLoadPlateSize(plateLoadSize) {
    this.plateLoadSize = plateLoadSize;    
  }

  onPlateLoadSet(plateLoad) {
    this.plateLoad = plateLoad;
    this.plateLoadIncrement = plateLoad.load;
  }

  add() {
    if (this.plateLoad == null) {
      return;
    }

    var r = this.currentLoad % this.plateLoad.load;
    if (r != 0) {
      alert("Invalid load");
      return;
    }


    this.loads.push(this.currentLoad);
    this.currentLoad = null;
  }

  removeLoad(load) {
    let index = this.loads.indexOf(load);
    this.loads.splice(index, 1);
  }

  async save() {
    var dto = {
      id: this.id,
      testTypeName: this.testName,
      bsStandard: this.bsStandard,
      minuteIncrement: this.minuteIncrement,
      testSpecification: this.testSpecification,
      loadIncrement: this.plateLoadIncrement,
      //loadIncrement: 

      plateSizeId: this.plateSize.id,
      plateLoadSizeId: this.plateLoadSize.id,
      plateLoads: []
    };

    for (let load of this.loads) {
      var loadDto = {
        load: load
      }

      dto.plateLoads.push(loadDto);
    }

    console.log(dto);
    if (this.id) {
      await this.plateTestService.update(dto);
    } else {
      await this.plateTestService.add(dto);
    }

    this.router.navigate(['testtypes']);
  }
}
