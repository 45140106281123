import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-account-picker',
  templateUrl: './account-picker.component.html',
  styleUrls: ['./account-picker.component.scss']
})
export class AccountPickerComponent implements OnInit, OnChanges {
  @Input() existingAccountId: number;
  @Output() onAccountIdSet = new EventEmitter<number>();

  accountId: number;

  accounts = [];
  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService.get().then((accounts) => {
      this.accounts = accounts;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existingAccountId != null && changes.existingAccountId.currentValue) {
      this.accountId = this.existingAccountId;
    }
  }

  accountChanged() {
    this.onAccountIdSet.emit(this.accountId);
  }
}
