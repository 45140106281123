import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ProjectService } from 'src/app/services/project.service';
import { JobService } from 'src/app/services/job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactionLoadService } from 'src/app/services/reaction-load.service';

@Component({
  selector: 'app-project-manage',
  templateUrl: './project-manage.component.html',
  styleUrls: ['./project-manage.component.scss']
})
export class ProjectManageComponent implements OnInit {

  id: string = null;
  accountId: number;
  projectId: number;
  poNumber: string;
  activated: boolean = false;
  departmentId: number;

  serviceProductId:number = 10;
  serviceProduct;

  customer;
  role;
  isPrimary: boolean = true;
  reportType;

  customerRoles = [];

  test;
  rate: number;

  tests = [];
  testTypes = [];
  reportTypeId: number = 0;
  notes: string;

  constructor(private snotifyService: SnotifyService, private projectService: ProjectService,
    private jobService: JobService, private route: ActivatedRoute, private router: Router) {
    this.projectService.getServiceProduct(this.serviceProductId).then((data) => {
      this.setServiceProduct(data);
      this.reportTypeId = 1;
    });
  }

  ngOnInit(): void {
    // Check to see if we are editing
    this.route.paramMap.subscribe((paramsMap) => {
      var id = paramsMap.get("id");
      if (id != null) {
        this.jobService.get(id).then((job) => {
          this.loadJob(job);
        });
      }
    });

   // this.loadDefaultDetails();
    
  }
  // loadDefaultDetails(){
  //   this.projectId = 6810;
  //   this.accountId = 2;
  // }

  back() {
    this.router.navigate(['project']);
  }

  loadJob(job) {
    this.id = job.id;
    this.accountId = job.accountId;
    this.projectId = job.projectId;
    this.poNumber = job.purchaseOrderNo;
    this.departmentId = job.project.departmentId;
    this.serviceProductId = job.serviceProductId;
    this.serviceProduct = job.serviceProduct;
    this.activated = job.activated;
    this.notes = job.notes;

    for (let customer of job.jobCustomers) {
      this.customerRoles.push(customer);
    }

    for (let test of job.jobTests) {
      this.tests.push(test);
    }  
    for (let test of job.jobTestTypes) {
      this.testTypes.push(test);
    }
  }

  accountChanged(): void {
    this.customerRoles = [];
  }

  setProject(project): void {
    this.projectId = project.projectId;
    this.departmentId = project.departmentId;
    this.customerRoles = [];
  }

  setServiceProduct(serviceProduct): void {
    console.log("serviceProduct");
    console.log(serviceProduct);
    this.serviceProduct = serviceProduct;
  }

  addCustomerRole(): void {
    if (this.role == undefined || this.customer == undefined)
      return;

    var role = {
      customer: this.customer,
      projectRole: this.role,
      isPrimary: this.isPrimary
    }

    if (this.customerRoles.find(c => c.customer == this.customer && c.projectRole == this.role)) {
      alert("Already added customer & role");
      return;
    }

    this.customerRoles.push(role);

    this.isPrimary = true;
  }
  setReportType(reportType) {
    this.reportType = reportType;
  }
  addTestType() {
    var test = [];
    for (var i = 0; i < this.testTypes.length; i++) {
      test.push(this.testTypes[i]);
    }
    var testType = {
      poNumber: this.poNumber,
      reportType: this.reportType,
      reportTypeId: this.reportType.reportTypeId
    };
    test.push(testType);
    this.testTypes = test;
    this.poNumber = "";
    this.reportTypeId = 0;

  }
  removeTestType(type): void {
    var test = [];
    for (var i = 0; i < this.testTypes.length; i++) {
      test.push(this.testTypes[i]);
    }

    let index = test.indexOf(type);
    test.splice(index, 1);
    this.testTypes = test;
  }
  removeCustomerRole(customerRole): void {
    let index = this.customerRoles.indexOf(customerRole);
    this.customerRoles.splice(index, 1);
  }

  setTest(test): void {
    this.test = test;
    console.log(test);
  }

  addTest() {
    if (this.test == null)
      return;
    
    if (this.tests.find(t => t.testVariantName == this.test.testVariantName)) {
      alert("Already added test");
      return;
    }

    this.tests.push(this.test);
  }

  removeTest(test): void {
    let index = this.tests.indexOf(test);
    this.tests.splice(index, 1);
  }

  async validate() {
    console.log(this.customerRoles);
    if (this.accountId == null) {
      alert("Select Account");
      return false;
    }

    if (this.projectId == null) {
      alert("Select Project");
      return false;
    }

    // if (this.poNumber == null || this.poNumber == "") {
    //   alert("Enter PO Number");
    //   return false;
    // }

    // if (this.serviceProduct == null) {
    //   alert("Service Product");
    //   return false;
    // }

    // Ensure we have a primary contact for each role
    var roles = await this.projectService.getRoles();
    var mandatoryRoles = roles.filter(a => a.mandatoryRole).map(a => a.projectRoleId);
    for (let id of mandatoryRoles) {
      if (this.customerRoles.filter(a => a.isPrimary && a.projectRole.projectRoleId == id).length == 0) {
        var neededRole = roles.find(a => a.projectRoleId == id);
        alert("Select primary role for: " + neededRole.description);
        return false;
      }
    }

    if (this.tests.length == 0) {
      alert("Minimum of 1 test required");
      return false;
    }

    return true;
  }

  async save() {
    var passedValidation = await this.validate();
    if (!passedValidation) {
      return;
    }

    this.jobService.checkDupe(this.id, this.accountId, this.projectId, this.poNumber).then((data) => {
      console.log("data");
      console.log(data);
      if (data != null) {
        this.snotifyService.error("There is already a Project with these details");
        return;
      } else {

        var dto = {
          id: this.id,
          accountId: this.accountId,
          projectId: this.projectId,
          purchaseOrderNo: this.poNumber,
          serviceProductId: this.serviceProduct.serviceProductId,
          activated: this.activated,
          notes: this.notes,
          fromManagement: true,
          jobTestTypes:[],
          jobCustomers: [],
          jobTests: []
        }

        for (let customerRole of this.customerRoles) {
          dto.jobCustomers.push({
            id: customerRole.id,
            customerId: customerRole.customer.customerId,
            projectRoleId: customerRole.projectRole.projectRoleId,
            isPrimary: customerRole.isPrimary
          });
        }

        for (let test of this.tests) {
          dto.jobTests.push({
            id: test.id,
            testVariantId: test.testVariantId,
            quoteTestVariantId: test.quoteTestVariantId
          })
        }
        for (let test of this.testTypes) {
          dto.jobTestTypes.push({
            reportTypeId: test.reportTypeId,
            poNumber: test.poNumber
          })
        }

        if (this.id) {
          this.jobService.updateJob(dto);
        } else {
          this.jobService.addJob(dto);
        }

        this.router.navigate(['project']);

      }
    })
  }
}
