<div class="content">
  <p-confirmDialog width="425" header="Are you sure you with to remove this job?" #cd>
    <p-footer>
      <button type="button" pButton icon="fa fa-close" label="No" class="btn btn-danger" (click)="cd.reject()"></button>
      <button type="button" pButton icon="fa fa-check" label="Yes" class="btn btn-success" (click)="cd.accept()"></button>
    </p-footer>
  </p-confirmDialog>

  <div class="filters-content">
    <div class="filter-row">
      <div style=" width: 90%; margin-right: 1%; display: flex; flex-direction: column; justify-content: center;">
        <label>Test Type</label>
        <app-report-type-picker [existingReportTypeId]="filter.reportTypeId" (onSet)="setReportType($event)"></app-report-type-picker>
      </div>

      <div class="search-btn">
        <input type="button" class="btn btn-success" value="Search" (click)="loadJobs()" />
      </div>
    </div>
  </div>

  <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10"
                 [sorts]="[{prop: 'scheduledDate', dir: 'asc'}]">

    <ngx-datatable-column name="Project" prop="projectName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Account" prop="accountName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Service Product" prop="serviceProductName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column> -->

    <ngx-datatable-column name="Scheduled Date" prop="scheduledDate">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value | date: 'dd/MM/yyyy' }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Test" prop="report_Type">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Technician" prop="technician">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Arrival Time" prop="arrivalTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Departure Time" prop="departureTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Notes" prop="notes">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="['/scheduler/manage', row.id]">Edit</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Remove">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" style="color: #b82504 " (click)="showRemoveJobPopup(row)">Remove</a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>
