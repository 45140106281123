import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasketItem } from './../components/basket/basket-item';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ReportableService {
  constructor(private http: HttpClient) { }


  public getConcretePourPreview(jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getConcretePourbyjob?jobId=' + jobId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getConcretePourByJob(jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getConcretePourbyjob?jobId=' + jobId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getConcretePour(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getConcretePour?id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        console.log(data);
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPlateBearing(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getplatebearing?id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        console.log(data);
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getBatch(data: Array<BasketItem>): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(environment.apiUrl + 'invoice/postBatch');
      console.log(data);
      this.http.post<any>(environment.apiUrl + 'invoice/postBatch', data).subscribe(data => {
        // console.log(data);
        // let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPlateBearingPreview(jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getplatebearingpreview?jobId=' + jobId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPlateBearingByJob(jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getplatebearingbyjob?jobId=' + jobId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
