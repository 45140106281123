import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyPosition, SnotifyService, ToastDefaults } from 'ng-snotify';
import { JobPlateTestService } from 'src/app/services/job-plate-test.service';
import { JobService } from 'src/app/services/job.service';
import { ConcretePourService } from '../../../../services/concrete-pour.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-review-manage',
  templateUrl: './review-manage.component.html',
  styleUrls: ['./review-manage.component.scss']
})
export class ReviewManageComponent implements OnInit {

  rows = [];

  job;
  jobType: number = 0;
  po: string;

  constructor(@Inject(DOCUMENT) private document: Document, private route: ActivatedRoute, private router: Router,
    private jobPlateTestService: JobPlateTestService, private concretePourService: ConcretePourService, private jobService: JobService, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    // Check to see if we are editing
    this.loadTable();
  }

  loadTable() {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      var id = params['id'];
      var type = params["jobType"];
      if (type == undefined || type == null)
        this.jobType = 1;
      else
        this.jobType = +type;

     
      if (id == null || id == undefined) {/// This shouldnt happen, but its here in case
        var ii = this.document.location.href.replace('http://bhp.corerfid.net/#/reviews/manage/', '');
        ii = ii.replace('https://bhp.corerfid.net/#/reviews/manage/', '');
        ii = ii.replace('http://bhp.dev5.corerfid.com/#/reviews/manage/', '');
        ii = ii.replace('http://localhost:4200/#/reviews/manage/', '');
        console.log(ii);
        id = ii;
      }
      console.log("this.jobType: " + this.jobType);
      console.log("id: " + id);

      if (id != null && this.jobType < 2) { //Id is not null AND is Plate Bearing
        this.jobPlateTestService.list(id).then((tests) => {
          console.log(tests);
          this.rows = tests;
        });

      } else if (id != null && this.jobType == 2) {
        this.concretePourService.list(id).then((tests) => {
          console.log(tests);
          this.rows = tests;
        });
      }

      if (id != null) {
        this.jobService.get(id).then((job) => {
          this.job = job;
          this.po = job.purchaseOrderNo;
        });
      }
    });
    //this.route.paramMap.subscribe((paramsMap) => {
    //  console.log(paramsMap);
    //  var id = paramsMap.get("id");
    //  var type = paramsMap.get("jobType");
    //  if (type == undefined || type == null)
    //    this.jobType = 1;
    //  else
    //    this.jobType = +type;

    //  console.log("this.jobType: " + this.jobType);
    //  console.log("id: " + id);

    //  if (id != null && this.jobType < 2) { //Id is not null AND is Plate Bearing
    //    this.jobPlateTestService.list(id).then((tests) => {
    //      console.log(tests);
    //      this.rows = tests;
    //    });

    //  } else if (id != null && this.jobType == 2) {
    //    this.concretePourService.list(id).then((tests) => {
    //      console.log(tests);
    //      this.rows = tests;
    //    });
    //  }



    //  if (id != null) {
    //    this.jobService.get(id).then((job) => {
    //      this.job = job;
    //      this.po = job.purchaseOrderNo;
    //    });
    //  }
    //});
  }

  back() {
    this.router.navigate(['reviews']);
  }

  archiveReport(rep) {
    console.log(rep);
    if (confirm("Are you sure you wish to archive this report?")) {
      this.jobPlateTestService.archive(rep.id).then((data) => {
        this.loadTable();
      })
    }
  }
  archiveConcreteReport(rep) {
    console.log(rep);
    if (confirm("Are you sure you wish to archive this report?")) {
      this.concretePourService.archive(rep.id).then((data) => {
        this.loadTable();
      })
    }
  }

  async save() {
    this.job.purchaseOrderNo = this.po;
    await this.jobService.updateJobPO(this.job);
    // this.snotifyService.config = ToastDefaults;
    this.snotifyService.success('Successfully updated job');
  }
}
