<div class="btn-content">
    <a [routerLink]="['/testtypes/manage']" class="btn btn-success">Add New</a>
</div>

<div class="content">
    <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
        [rowHeight]="'auto'" [limit]="10">

        <ngx-datatable-column name="Test" prop="testName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Test Type" prop="testTypeName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Size" prop="plateSizeName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Option">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/testtypes/manage', row.id]">Edit</a>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>