import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PlateTestService } from 'src/app/services/plate-test.service';

@Component({
  selector: 'app-plate-load-picker',
  templateUrl: './plate-load-picker.component.html',
  styleUrls: ['./plate-load-picker.component.scss']
})
export class PlateLoadPickerComponent implements OnInit, OnChanges {
  @Input() plateLoadIncrement: number;
  @Output() onPlateLoadSet = new EventEmitter<any>();

  plateLoadId: number;
  plateLoads = [];

  constructor(private plateTestService: PlateTestService) {
  }

  ngOnInit(): void {
    this.plateTestService.getPlateLoads().then((loads) => {
      this.plateLoads = loads;

      this.updateByLoad();
    });
  }

  updateByLoad() {
    if (this.plateLoads.length > 0 && this.plateLoadIncrement != null) {
      var load = this.plateLoads.find(a => a.load == this.plateLoadIncrement);
      if (load != null) {
        this.plateLoadId = load.id;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateByLoad();
  }

  plateLoadChanged() {
    var plateLoad = this.plateLoads.find(a => a.id == this.plateLoadId);
    this.onPlateLoadSet.emit(plateLoad);
  }

}
