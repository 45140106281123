import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss']
})
export class CustomerPickerComponent implements OnInit, OnChanges {
  @Input() accountId: number;
  @Output() onCustomerIdSet = new EventEmitter<number>();
  @Output() onCustomerSet = new EventEmitter<any>();

  customerId: number;
  customers = [];

  constructor(private customerService: CustomerService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accountId != null && changes.accountId.currentValue) {
      this.customerService.get(this.accountId).then((customers) => {
        this.customers = customers;
      });
    }
  }

  customerChanged() {
    this.onCustomerIdSet.emit(this.customerId);
    var customer = this.customers.find(a => a.customerId == this.customerId);
    this.onCustomerSet.emit(customer);
  }

}
