import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { WebUserService } from 'src/app/services/webuser.service';

@Component({
  selector: 'app-user-profile-picker',
  templateUrl: './user-profile-picker.component.html',
  styleUrls: ['./user-profile-picker.component.scss']
})
export class UserProfilePickerComponent implements OnInit {
  @Input() userId: number;
  @Output() onProfileSet = new EventEmitter<any>();

  userProfiles = [];

  constructor(private webUserService: WebUserService) {
  }

  ngOnInit(): void {
    this.webUserService.getProfiles().then((profiles) => {
      this.userProfiles = profiles;
    });
  }

  userChanged() {
    var user = this.userProfiles.find(a => a.userId == this.userId);
    this.onProfileSet.emit(user);
  }

}
