import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { SnotifyService } from 'ng-snotify';

@Injectable()
export class IssueService {
    constructor(private http: HttpClient, private authService: AuthService, private snotifyService: SnotifyService) {
    }

    public issue(id, selectedJobTestForInvoicingId): Promise<any> {
        return new Promise((resolve, reject) => {
            var user = this.authService.currentUser.value;
            console.log(user);

            let api = environment.apiUrl + 'issue/post';

            api += '?jobId=' + id + '&selectedJobTestForInvoicingId=' + selectedJobTestForInvoicingId + '&issuedBy=' + user.userProfileId + '&approvedByUserId=' + user.id;

            console.log(api);

            this.http.post<any>(api, null).subscribe(data => {
                this.snotifyService.success("Successfully Approved.");
                resolve(data);
            }, error => {
                console.log(error);
                this.snotifyService.error("There was a error sending this request");
                reject();
            });
        });
    }
    public issueAgain(id, selectedJobTestForInvoicingId): Promise<any> {
        return new Promise((resolve, reject) => {
            var user = this.authService.currentUser.value;
            console.log(user);

            let api = environment.apiUrl + 'issue/post';

            api += '?jobId=' + id + '&selectedJobTestForInvoicingId=' + selectedJobTestForInvoicingId + '&issuedBy=' + user.userProfileId + '&approvedByUserId=' + user.id;

            console.log(api);

            this.http.post<any>(api, null).subscribe(data => {
                this.snotifyService.success("Successfully Sent Request.\nThis may take a while for the consignment number to be returned");
                resolve(data);
            }, error => {
               // console.log(error);
                console.log(error.error);
                this.snotifyService.error(error.error);
                reject();
            });
        });
    }

    public generateSamples(id): Promise<any> {
        return new Promise((resolve, reject) => {
            var user = this.authService.currentUser.value;
            console.log(user);

            let api = environment.apiUrl + 'issue/generatesamples';

            api += '?jobId=' + id;

            console.log(api);

            this.http.post<any>(api, null).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
