import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { ServiceProductsService } from 'src/app/services/service-products.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ReportType } from 'src/app/models/report-type';

@Component({
  selector: 'app-quote-test-picker',
  templateUrl: './quote-test-picker.component.html',
  styleUrls: ['./quote-test-picker.component.scss']
})
export class QuoteTestPickerComponent implements OnInit {
  @Input() projectId: number;
  @Input() serviceProductId: number;
  @Input() reportTypes: ReportType[];
  @Output() onTestSet = new EventEmitter<any>();

  quoteTestVariantId: number;
  testVariants = [];

  constructor(private quoteService: QuoteService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges");
    console.log(changes);
    if (changes.projectId != undefined && changes.projectId.currentValue != undefined)
      this.projectId = changes.projectId.currentValue;
    if (changes.serviceProductId != undefined && changes.serviceProductId.currentValue != undefined)
      this.serviceProductId = changes.serviceProductId.currentValue;

    if (this.projectId != null && this.serviceProductId != null) {
      if(this.reportTypes == null){
        // this.quoteService.getProjectQuoteTests(this.projectId, this.serviceProductId).then((variants) => {
        //   this.testVariants = variants;
        //   console.log("testVariants");
        //   console.log(this.testVariants);
        // });
      }
      else{
        console.log(this.reportTypes);
        console.log(this.reportTypes.length);
        var t = [];
        for(var i = 0; i <this.reportTypes.length; i++){
          var te = this.reportTypes[i];
          t.push(te.reportTypeId);
        }
        console.log(t.length);

        this.quoteService.getProjectQuoteTests(this.projectId, this.serviceProductId,t.join(",")).then((variants) => {
          this.testVariants = variants;
          console.log("testVariants");
          console.log(this.testVariants);
        });
      }

    }
  }

  testChanged() {
    var test = this.testVariants.find(a => a.quoteTestVariantId == this.quoteTestVariantId);
    this.onTestSet.emit(test);
  }

}
