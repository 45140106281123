import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcretePourService } from '../../../../../services/concrete-pour.service';
import { concretepour } from '../../../../../models/concretepour';
import { weather } from '../../../../../models/weather';
import { JobService } from '../../../../../services/job.service';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-concrete-pour-review-manage-review',
  templateUrl: './concrete-pour-review.component.html',
  styleUrls: ['./concrete-pour-review.component.scss']
})
export class ConcretePourManageReviewComponent implements OnInit {
  id: string;
  jobId: string;
  inspection: concretepour;
  weather: weather[];
  compaction: [];
  constructor(private route: ActivatedRoute, private router: Router, public concretePourService: ConcretePourService,
    private jobService: JobService, private snotifyService: SnotifyService) {
    this.inspection = new concretepour();
    this.inspection.concretePours = [];
    jobService.getWeather().then((data) => {
      this.weather = data;
    });

    jobService.getCompactionMethods().then((data) => {
      this.compaction = data;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (paramsMap) => {
      var id = paramsMap.get("id");
      var jobId = paramsMap.get("jobId");
      
      if (jobId != null) {
        this.jobId = jobId;
        this.concretePourService.get(id).then((data) => {
          console.log("data");
          console.log(data);

          for (var a = 0; a < data.concretePours.length; a++) {
            var p = data.concretePours[a];
            p.batchTime = moment(p.batchTime).toDate();
            p.endTime = moment(p.endTime).toDate();
            p.onSite = moment(p.onSite).toDate();
            p.pour_Date = moment(p.pour_Date).toDate();
            p.startTime = moment(p.startTime).toDate();
          }

          this.inspection = data;
        });
      }

      if (id != null) {
        this.id = id;

      }
    });
  }
  amountPouredChanged() {
    var total = 0; 
    for (var a = 0; a < this.inspection.concretePours.length; a++) {
      var p = this.inspection.concretePours[a];
      total += p.amount_Poured;
      p.total_Amount_Poured = total;
    }
  }
  setTechnician(technician) {
    this.inspection.userId = technician.id;
    this.inspection.technician = technician;
  }
  setConcreteStrength(pour, strength) {
    console.log(strength);
    pour.concreteStrength = strength;
  }

  update() {
    console.log(this.inspection);
    this.concretePourService.post(this.inspection).then((data) => {
      this.snotifyService.success("Successfuly updated inspection");
      this.back();
    });

  }

  back() {
    this.router.navigate(['reviews/manage'], { queryParams: { id: this.jobId, jobType: 2 } });
  }
}
