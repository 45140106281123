import { Component, OnInit } from '@angular/core';
import { WebUserService } from 'src/app/services/webuser.service';

@Component({
  selector: 'app-webusers',
  templateUrl: './webusers.component.html',
  styleUrls: ['./webusers.component.scss']
})
export class WebusersComponent implements OnInit {

  rows = [];
  constructor(private webUsersService: WebUserService) { }

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    this.webUsersService.getTable().then((rows) => {
      this.rows = rows;
    });
  }
}
