<div class="content">
  <button class="btn btn-light btn-back" (click)="back()">Back</button>

  

  <div class="form-groups-column buffer-bottom">
    <div class="form-group">
      <label>Firstname</label>
      <input type="text" class="form-control" [(ngModel)]="firstname" />
    </div>

    <div class="form-group">
      <label>Lastname</label>
      <input type="text" class="form-control" [(ngModel)]="lastname" />
    </div>

    <div class="form-group">
      <label>Pin</label>
      <input type="password" class="form-control" [(ngModel)]="pin" />
    </div>

    <div class="form-group">
      <label>Confirm Pin</label>
      <input type="password" class="form-control" [(ngModel)]="pin2" />
    </div>

    <div class="form-group">
      <label>Expired</label>
      <input style="width:15px;" type="checkbox" [(ngModel)]="expired" />
    </div>

    <div class="form-group">
      <label>Archive</label>
      <input style="width:15px;" type="checkbox" [(ngModel)]="archived" />
    </div>
  </div>

  <button class="btn btn-success btn-save" (click)="save()">Save</button>
</div>
