import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobPlateTestService } from 'src/app/services/job-plate-test.service';
import { ForceCalculatorService } from 'src/app/services/force-calculator.service';
import { ReactionLoadService } from 'src/app/services/reaction-load.service';
import { MaterialService } from 'src/app/services/material.service';
import * as moment from 'moment';

@Component({
  selector: 'app-plate-bearing-manage-review',
  templateUrl: './plate-bearing-review.component.html',
  styleUrls: ['./plate-bearing-review.component.scss']
})
export class PlateBearingManageReviewComponent implements OnInit {
  id: string;
  jobId: string;
  test;
  created_Date:string;

  reactionLoads = [];
  showReactionLoadOther = false;
  otherReactionLoad: string;

  materials = [];

  constructor(private route: ActivatedRoute, private jobPlateTestService: JobPlateTestService,
    private forceCalculatorService: ForceCalculatorService, private router: Router, 
    private reactionLoadService: ReactionLoadService, private materialService: MaterialService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (paramsMap) => {
      var id = paramsMap.get("id");
      var jobId = paramsMap.get("jobId");
      
      if (jobId != null) {
        this.jobId = jobId;
      }

      if (id != null) {
        this.id = id;

        this.reactionLoads = await this.reactionLoadService.get();
        this.materials = await this.materialService.get();
        //console.log(this.materials);

        this.jobPlateTestService.get(this.id).then((test) => {
          test.jobPlateTestLoads = test.jobPlateTestLoads.sort((a, b) => (a.position - b.position));
          console.log(test);
          var idx = this.reactionLoads.findIndex(p => p.load == test.typeOfReactionLoad);
          if (idx == -1) {
            this.otherReactionLoad = test.typeOfReactionLoad;
            
            this.showReactionLoadOther = true;
            test.typeOfReactionLoad = "Other";
          }

          this.test = test;
          this.formatForDisplay(this.test);
          this.created_Date = this.test.createdAt;

          
        });
      }
    });
  }

  back() {
    this.router.navigate(['reviews/manage'], { queryParams: { id: this.jobId, jobType: 1 } });
    // this.router.navigate(['reviews/manage', this.jobId]);
  }

  formatForDisplay(test) {
    for (let load of test.jobPlateTestLoads) {
      var c = load.avg;
      if (!this.getNumberOfDecimalPlaces(load.load1)) {
        load.load1 = this.roundToTwo((Math.round(load.load1 * 100) / 100)); //.toFixed(2);
        // load.load1 = load.load1.toFixed(2);
      } if (!this.getNumberOfDecimalPlaces(load.load2)) {
        load.load2 = this.roundToTwo((Math.round(load.load2 * 100) / 100)); //.toFixed(2);
        // load.load2 = load.load2.toFixed(2);
      } if (!this.getNumberOfDecimalPlaces(load.load3)) {
        load.load3 = this.roundToTwo((Math.round(load.load3 * 100) / 100)); //.toFixed(2))
        //  load.load3 = load.load3.toFixed(2);
      } if (!this.getNumberOfDecimalPlaces(load.avg)) {
        load.avg = this.roundToTwo((Math.round(load.avg * 100) / 100)); //.toFixed(2);
        // load.avg = load.avg.toFixed(2);
      }
      if (c != load.avg) {
        console.log("formatForCalculation");
        console.log(c);
        console.log(load.avg);
        
      }
    }
  }

  formatForCalculation(test) {
    for (let load of test.jobPlateTestLoads) {
      var c = load.avg;
      
      load.load1 = Math.round(load.load1 * 100) / 100;
      load.load2 = Math.round(load.load2 * 100) / 100;
      load.load3 = Math.round(load.load3 * 100) / 100;
      load.avg = Math.round(load.avg * 100) / 100;
      if (c != load.avg) {
        console.log("formatForCalculation");
        console.log(c);
        console.log(load.avg);
      }

    }
  }

  recalculate(formatForDisplay: boolean) {
    if (this.validate()) {
     this.formatForCalculation(this.test);

      var calculation = this.forceCalculatorService.calculate(
        this.test.jobPlateTestLoads,
        this.test.plateTestType.loadIncrement,
        this.test.plateTestType.plateLoadSize.load);

      this.test.cbr = calculation.cbr;
      this.test.force = calculation.force;
      this.test.k = calculation.k;
      //console.log(calculation);

      if (formatForDisplay) {
        this.formatForDisplay(this.test);
      }
    }
  }

  update() {
    this.recalculate(false);


    if (this.otherReactionLoad != null && this.otherReactionLoad != "") {
      this.test.typeOfReactionLoad = this.otherReactionLoad;
    }

    for (var a = 0; a < this.test.jobPlateTestLoads.length; a++) {
      var t = this.test.jobPlateTestLoads[a];
      if (t.avg == null || Number.isNaN(t.avg))
        t.avg = 0;
    }
    this.test.createdAt = moment(moment(this.test.createdAt).format("YYYY-MM-DD") + " " + moment(this.created_Date).add(1, 'hours').format("HH:mm:ss"));

    console.log(this.test);
    this.jobPlateTestService.update(this.test).then(data => {
      this.back();
    }, error => {
      console.log(error.error);
    });
  }

  validate() {
    let isValid: boolean = true;

    for (let load of this.test.jobPlateTestLoads) {
      if (load.load1 == undefined || load.load1 == 'undefined' || load.load1 == null) {
        isValid = false;
        alert("Please ensure all values are filled");
        break;
      } if (load.load1 != 0.0) {
        if (!this.getNumberOfDecimalPlaces(load.load1)) {
          isValid = false;
          alert("Please ensure values are 2 decimal places");
          break;
        }
      } if (load.load2 == undefined || load.load2 == 'undefined' || load.load2 == null) {
        isValid = false;
        alert("Please ensure all values are filled");
        break;
      } if (load.load2 != 0.0) {
        if (!this.getNumberOfDecimalPlaces(load.load2)) {
          isValid = false;
          alert("Please ensure values are 2 decimal places");
          break;
        }
      } if (load.load3 == undefined || load.load3 == 'undefined' || load.load3 == null) {
        isValid = false;
        alert("Please ensure all values are filled");
        break;
      } if (load.load3 != 0.0) {
        if (!this.getNumberOfDecimalPlaces(load.load3)) {
          isValid = false;
          alert("Please ensure values are 2 decimal places");
          break;
        }
      }
    }

    return isValid;
  }

  getNumberOfDecimalPlaces(value: string) {
    let parts: string[] = value.toString().split(".", 2);
    return (parts.length == 2 && parts[1].length == 2);
  }


  reactionLoadChanged() {
    this.otherReactionLoad = "";
    this.showReactionLoadOther = this.test.typeOfReactionLoad == "Other";
  }
  private roundToTwo(val: any ){
    let log: boolean;
    if(val == 1.65 || val == 1.66) log = true;
      else log = false;
      if(log){
        console.log("roundToTwo");
        console.log(val);
      }

    if(isNaN(val))
      return "0.00";
    let prefix = parseInt(val);
    var cal = val - prefix;
    if (log) {
      console.log(prefix);
      console.log(cal);
    }
    if (cal == 0.55){
      console.log(".55");
      val = val + 0.05;
    }
    if (log) {
      console.log(val.toFixed(2));
    }
    return val.toFixed(2);
  }
}
