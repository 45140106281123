<div class="btn-content "*ngIf="rows.length > 0">
    <button type="button" class="btn btn-success btn-selected" (click)="invoiceSelected()">Invoice Selected</button>
    <button type="button" class="btn btn-success" (click)="invoiceAll()">Invoice All</button>
</div>

<div class="content">
    <form (ngSubmit)="onSearch()" *ngIf="false">
        <div class="form-group">
            <label>Purchase Order #</label>
            <input class="form-control" [(ngModel)]="search" name="search" />
        </div>

        <input type="submit" class="add-btn" value="Search" />
    </form>

    <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
        [rowHeight]="'auto'" [limit]="10">

        <ngx-datatable-column name="Project" prop="project">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Account" prop="account">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Consignment #" prop="consignmentNo">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <!-- <ngx-datatable-column name="Sales Order #" prop="salesOrderNo">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column name="# of Samples" prop="noOfSamples">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Invoice">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <input type="checkbox" [(ngModel)]="row.selected">
            </ng-template>
          </ngx-datatable-column>
    </ngx-datatable>
</div>