<p-dialog header="Admin PIN Confirmation " [(visible)]="displayDialog" [modal]="true"
  [contentStyle]="{'min-height': '100px', 'overflow-y': 'auto', 'width': '435px'}" [closable]="false">

  <div class="flex" style="display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;align-items: center;justify-content: center;">
    <p><b><label for="pwd">Admin PIN:</label></b></p>
    <input type="password" id="pwd" name="pwd" [(ngModel)]="adminPIN">
  </div>
  <p-footer>
    <button type="button" (click)="cancel()" label="Cancel" class="btn btn-primary">Cancel</button>
    <button type="button" (click)="confirm()" label="Save" class="btn btn-success">Confirm</button>
  </p-footer>
</p-dialog>

<div class="filters-content">
  <div class="filter-row">
    <div class="filter">
      <label>Project</label>
      <input type="text" [(ngModel)]="filter.project" />
    </div>

    <div class="filter">
      <label>Account</label>
      <input type="text" [(ngModel)]="filter.account" />
    </div>

    <div class="filter" *ngIf="false">
      <label>Purchase Order</label>
      <input type="text" [(ngModel)]="filter.purchaseOrder" />
    </div>

    <div class="filter">
      <label>Start Date</label>
      <div class="filter-picker">
        <input matInput [matDatepicker]="pickerStart" [(ngModel)]="filter.startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </div>
    </div>

    <div class="filter">
      <label>End Date</label>
      <div class="filter-picker">
        <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="filter.endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </div>
    </div>

    <div class="search-btn">
      <input type="button" class="btn btn-success" value="Search" (click)="loadTable()"/>
    </div>
  </div>
</div>
<div class="row buffer-bottom" style="margin-left: 40px;">
  <app-basket (click)="refreshRows()"></app-basket>
</div>

<div class="row buffer-bottom" style="margin-left: 20px;">
  <i (click)="addAllToBasket()" class="fa fa-plus" style="margin-left: 20px; cursor: pointer;"></i>
</div>

<div class="content">
    <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
        [rowHeight]="'auto'" [limit]="10">

        <ngx-datatable-column name="Basket">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <i *ngIf="row.added == undefined || row.added == false" (click)="addToBasket(row)" class="fa fa-plus" style="margin-left:10px; margin-right: 10px; cursor: pointer;"></i>
            <i *ngIf="row.added != undefined && row.added == true" (click)="removeFromBasket(row)" class="fa fa-minus" style="margin-left:10px; margin-right: 10px; cursor: pointer;"></i>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Download">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <a href="javascript:void(0)" (click)="downloadReport(row.id)">Download</a>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Project" prop="projectName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Account" prop="accountName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo" *ngIf="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Technician" prop="technician">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Created At" prop="createdAt">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value | date: 'dd/MM/yyyy HH:mm' }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Option">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a href="javascript:void(0)" (click)="archiveReport(row)">Archive</a>
          </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Option">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a href="javascript:void(0)" (click)="editReport(row)">Edit</a>
        </ng-template>
    </ngx-datatable-column>
    </ngx-datatable>
</div>