import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { AccountService } from 'src/app/services/account.service';
import { validate } from 'json-schema';
import { SnotifyService } from 'ng-snotify';
import { JobService } from 'src/app/services/job.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SaveFilterService } from 'src/app/services/save-filter.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  rows;
  filter = {
    project: "",
    account: "",
    purchaseOrder: ""
  }

  constructor(private authService: AuthService, private saveFilterService:SaveFilterService,private jobService: JobService, private router: Router) { }

  ngOnInit(): void {
    var savedFilter = this.saveFilterService.getFilters(this.authService.currentUser.value.id, 'projects');
    if(savedFilter != null){
      this.filter = savedFilter;
      //this.filter.endDate = moment().toISOString()
    }

    this.loadTable();
  }

  onSearch() {
    this.loadTable();
  }

  loadTable() {
    // if (this.search == "")
    //   return;
  this.saveFilterService.saveFilters(this.authService.currentUser.value.id, 'projects', this.filter);
    this.jobService.getTable(this.filter.project, this.filter.account, this.filter.purchaseOrder).then((rows) => {
      this.rows = rows;
    });
  }

  async toggleActivated(job) {
    var dbJob = await this.jobService.get(job.id);
    dbJob.activated = !dbJob.activated;

    await this.jobService.updateJob(dbJob);


    if (dbJob.activated) {
      this.router.navigate(['scheduler/manage', dbJob.id]);
      return;
    }

    this.loadTable();
  }
}
