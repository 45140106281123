import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class QuoteService {
    constructor(private http: HttpClient) {
    }

    public getProjectQuoteTests(projectId: number, serviceProductId: number, reportTypes:string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(environment.apiUrl + 'quote/getprojectquotetests?projectId=' + projectId + '&serviceProductId=' + serviceProductId + '&reportTypes=' + reportTypes);
            this.http.get<any>(environment.apiUrl + 'quote/getprojectquotetests?projectId=' + projectId + '&serviceProductId=' + serviceProductId+ '&reportTypes=' + reportTypes)
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
        });
    }
}
