import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';

@Injectable()
export class JobService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) {
  }

  public getTable(project: string, account: string, purchaseOrder: string, fromParent: boolean = false, activated: boolean = null,
    isScheduled = null, reportTypeId: number = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      var params = "";
      params += "?project=" + project;
      params += "&account=" + account;
      params += "&purchaseOrder=" + purchaseOrder;
      params += "&fromParent=" + fromParent;
      params += "&activated=" + activated;
      params += "&isScheduled=" + isScheduled;
      params += "&reportTypeId=" + reportTypeId;

      console.log(params);

      this.http.get<any>(environment.apiUrl + 'job/getTable' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getSchedularTable(project: string, account: string, purchaseOrder: string, fromParent: boolean = false, activated: boolean = null,
    isScheduled = null, reportTypeId: number = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      var params = "";
      params += "?project=" + project;
      params += "&account=" + account;
      params += "&purchaseOrder=" + purchaseOrder;
      params += "&fromParent=" + fromParent;
      params += "&activated=" + activated;
      params += "&isScheduled=" + isScheduled;
      params += "&reportTypeId=" + reportTypeId;

      console.log(params);

      this.http.get<any>(environment.apiUrl + 'job/getSchedularTable' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getProblematicTable(project: string, account: string, purchaseOrder: string, fromParent: boolean = false, activated: boolean = null, isScheduled = null): Promise<any> {
    return new Promise((resolve, reject) => {
      var params = "";
      params += "?project=" + project;
      params += "&account=" + account;
      params += "&purchaseOrder=" + purchaseOrder;
      params += "&fromParent=" + fromParent;
      params += "&activated=" + activated;
      params += "&isScheduled=" + isScheduled;

      console.log(params);

      this.http.get<any>(environment.apiUrl + 'job/getProblematicTable' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public checkDupe(id,account, project, purchaseOrder): Promise<any> {
    var params = "";
    params += "?project=" + project;
    params += "&account=" + account;
    params += "&purchaseOrder=" + purchaseOrder;
    params += "&id=" + id;

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/checkdupe' + params).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  
  public get(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getJobTests(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/getjobtests?id=' + job.id).subscribe(data => {
        resolve(data);
        console.log(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public addJob(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'job/post', job).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        alert(error.error);
        reject();
      });
    });
  }
  public async removeJob(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/remove?id=' + job.id).subscribe(data => {
        resolve(data);
        this.snotifyService.success("Successfully removed job");
      }, error => {
        console.log(error);
        if (error.error == null)
          error.error = error.statusText;
        this.snotifyService.error(error.error);
        reject();
      });
    });
  }

  public updateJob(job): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(job);
      this.http.put<any>(environment.apiUrl + 'job/put', job).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public updateJobPO(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/UpdateJobPO?JobId='+job.id + '&PO=' + job.purchaseOrderNo).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public duplicateJob(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put<any>(environment.apiUrl + 'job/duplicateJob', job).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public assignTestForInvoicing(id, chosenHours: number = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/assigntestforinvoicing?id=' + id + '&chosenHours='+chosenHours).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getWeather(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/getWeather').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getCompactionMethods(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'job/getCompactionMethods').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

}
