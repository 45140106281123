import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import { IssueService } from 'src/app/services/issue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  displayDialog: boolean = false;
  rows = [];

  filter = {
    project: "",
    account: "",
    purchaseOrder: "",
    reportTypeId: 0
  }



  constructor(private jobService: JobService, private issueService: IssueService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.loadJobs();
  }

  loadJobs() {

    this.jobService.getSchedularTable(this.filter.project, this.filter.account, this.filter.purchaseOrder, true, true, null,this.filter.reportTypeId).then((jobs) => {
      this.rows = jobs;
    });
  }
  async showRemoveJobPopup(job) {
    console.log(job);
    var JobDetails = 'Account: ' + job.accountName + '</br>';
    JobDetails += 'Project: ' + job.projectName + '</br>';
    JobDetails += 'Purchase Order: ' + job.purchaseOrderNo + '</br>';
    JobDetails += 'Notes: ' + job.notes + '</br>';

    this.confirmationService.confirm({
      message: '<br/>' + JobDetails,
      accept: () => {
        console.log("Removing");
        this.jobService.removeJob(job);
        console.log("Done");
       

        setTimeout(() => {
          console.log("Loading");
          this.loadJobs();
          console.log("Done");
        }, 300);
      }
    });

  }

  setReportType(event) {
    if (event != undefined)
      this.filter.reportTypeId = event.reportTypeId;
    else
      this.filter.reportTypeId = 0;
  }
}


