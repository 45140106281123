<div class="flexArea">
  <div class="btn-content flexgroup">
    <button class="btn btn-light btn-back" (click)="back()">Back</button>
  </div>
  <div class="flexgroup">
    <div class="flexArea">
      <div class="form-group" style="margin-right: 1%;">
        <label>PO #</label>
        <input name="po" type="text" class="form-control po" [(ngModel)]="po" />
      </div>
    </div>
  </div>
  <button class="add-btn" style="height: 4.75vh;margin-top: 1.25vh; margin-left: 1%; margin-right: 1%;" (click)="save()">Save</button>
</div>
<div class="content">
  <!-- [(ngModel)]="load.load1" -->

  <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10" *ngIf="jobType == 1">

    <ngx-datatable-column name="Plate Test" prop="plateTestType.testTypeName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Location Reference" prop="locationReference">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Material" prop="material.description">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Type Of Reaction Load" prop="typeOfReactionLoad">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="CBR" prop="cbr">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="K" prop="k">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="['/reviews/manage/review/plate-bearing/', row.id, row.job.id]">Review</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="archiveReport(row)">Archive</a>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10" *ngIf="jobType == 2">

    <ngx-datatable-column name="Delivery #" prop="concretePours[0].delivery_Number">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Cube Set Ref" prop="concretePours[0].cube_Set_Ref">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="PO #" prop="job.purchaseOrderNo">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Pour Element" prop="job.pour_Element">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="['/reviews/manage/review/concrete-pour/', row.id, row.job.id]">Review</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="archiveConcreteReport(row)">Archive</a>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
