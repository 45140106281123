import { Component, OnInit, Input } from '@angular/core';
import { BasketItem } from './basket-item';
import { BasketService } from './basket.service';
import * as FileSaver from 'file-saver';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  basketItems: Array<BasketItem>;
  title: string;

  isOpen: boolean = false;

  constructor(private basketService: BasketService, private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.basketService.basketItems.subscribe(data => {
      this.basketItems = data;
    });
  }

  downloadZip() {
    if (this.basketItems.length == 0) {
      this.snotifyService.error("There is nothing in the basket");
      return;
    }

    this.basketService.getZip().then((data => {
      FileSaver.saveAs(data, "Inspections.zip");
    }))
  }

  downloadBatch() {
    if (this.basketItems.length == 0) {
      this.snotifyService.error("There is nothing in the basket");
      return;
    }

    this.basketService.getBatch().then((data => {
      FileSaver.saveAs(data, "Inspections.pdf");
    }))
  }


  downloadSummary() {
    if (this.basketService.company_Id == 'undefined' || this.basketService.company_Id == undefined || this.basketService.company_Id == null) {
      this.snotifyService.error("Please select a company");
      return;
    } else if (this.basketService.location_Id == 'undefined' || this.basketService.location_Id == undefined || this.basketService.location_Id == null) {
      this.snotifyService.error("Please select a location");
      return;
    }

    if (this.basketItems.length == 0) {
      this.snotifyService.error("There is nothing in the basket");
      return;
    }

    let items = this.basketService.dataSource.filter(i => {
      return (i.company_Id != this.basketService.company_Id) || (i.location_Id != this.basketService.location_Id)
    });

    if (items.length > 0) {
      this.snotifyService.error("Invalid items in the basket");
      return;
    } else {
      this.basketService.getSummary().then((data => {
        FileSaver.saveAs(data, "Summary.pdf");
      }))
    }
    
  }

  download() {
    this.basketService.getBatch().then((data => {
      FileSaver.saveAs(data, "Batch.pdf");
    }))
  }

  clear() {
    this.basketService.clear();
  }

  remove(item) {
    this.basketService.remove(item);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

}
