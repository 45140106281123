import { Component, OnInit } from '@angular/core';
import { WebUserService } from 'src/app/services/webuser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-webusers-manage',
  templateUrl: './webusers-manage.component.html',
  styleUrls: ['./webusers-manage.component.scss']
})
export class WebusersManageComponent implements OnInit {

  id: string = null;
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  userId: number = 0;
  base64Signature: string = null;
  title: string = "";

  constructor(private webUserService: WebUserService, private router: Router, private route: ActivatedRoute, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramsMap) => {
      var id = paramsMap.get("id");
      if (id != null) {
        this.webUserService.get(id).then((user) => {
          console.log(user);
          this.id = user.id;
          this.firstname = user.firstname;
          this.lastname = user.lastname;
          this.email = user.email;
          this.password = user.password;
          this.password2 = user.password2
          this.title = user.title;
          this.userId = user.userProfileId;
        });
      }
    });
  }

  back() {
    this.router.navigate(['users']);
  }

  validate() {
    if (this.firstname == null || this.firstname == "") {
      //this.snotifyService.error("Enter firstname");
      alert("Enter firstname");
      return false;
    }

    if (this.lastname == null || this.lastname == "") {
      //this.snotifyService.error("Enter lastname");
      alert("Enter lastname");
      return false;
    }

    if (this.password == null || this.password == "") {
      //this.snotifyService.error("Enter password");
      alert("Enter password");
      return false;
    }

    if (this.password2 == null || this.password2 == "") {
      //this.snotifyService.error("Enter password");
      alert("Enter password");
      return false;
    }

    if (this.password != this.password2) {
      //this.snotifyService.error("Passwords do not match");
      alert("Passwords do not match");
      return false;
    }

    if (this.userId == 0) {
      //this.snotifyService.error("Select user profile");
      alert("Select user profile");
      return false;
    }

    if (this.id == null && this.base64Signature == null) {
      //this.snotifyService.error("Upload signature");
      alert("Upload signature");
      return false;
    }

    if (this.title == null || this.title == "") {
      //this.snotifyService.error("Enter password");
      alert("Enter title");
      return false;
    }

    return true;
  }

  async save() {
    if (!this.validate()) {
      return;
    }

    var user = {
      id: this.id,
      firstname: this.firstname,
      lastname: this.lastname,
      password: this.password,
      email: this.email,
      userProfileId: this.userId,
      base64Signature: this.base64Signature,
      title: this.title
    };

    if (this.id == null)
      await this.webUserService.add(user);
    else
      await this.webUserService.update(user);

    this.router.navigate(['users']);
  }

  async delete() {
    await this.webUserService.delete(this.id);
    this.router.navigate(['users']);
  }

  setUserProfile(user) {
    if (user.userId != null) {
      this.userId = user.userId;
    }
  }

  changeListener(e) {
    var rawBase64 = null;
    var base64 = null;

    // If a file was selected
    if (e.target.files.length > 0) {
      let file = e.target.files[0];

      if (file.type != 'image/png' && file.type != 'image/jpg') {
        //this.snotifyService.error("Please ensure image is either a PNG or JPG file");
        alert("Please ensure image is either a PNG or JPG file");
        (<HTMLInputElement>document.getElementById('imagePicker')).value = '';
        return;
      }

      var reader = new FileReader();

      reader.readAsDataURL(file);

      // Split the initial data type string from the base64 data
      reader.onload = (e) => {
        rawBase64 = reader.result;
        base64 = rawBase64.split(",", 2);
        this.base64Signature = base64[1];
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }
}
