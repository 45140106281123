import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-project-role-picker',
  templateUrl: './project-role-picker.component.html',
  styleUrls: ['./project-role-picker.component.scss']
})
export class ProjectRolePickerComponent implements OnInit {
  @Output() onRoleSet = new EventEmitter<any>();

  projectRoleId: number;
  roles = [];

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.getRoles().then((roles) => {
      this.roles = roles;
    });
  }


  roleChanged() {
    var role = this.roles.find(a => a.projectRoleId == this.projectRoleId);
    this.onRoleSet.emit(role);
  }

}
