import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TestService {
    constructor(private http: HttpClient) {
    }

    public get(departmentId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'test/get?departmentId=' + departmentId).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public getServiceTests(serviceProductId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'test/getservicetests?serviceProductId=' + serviceProductId).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
