import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConcreteStrengthService } from 'src/app/services/concrete-strength.service';


@Component({
  selector: 'app-concrete-strength-picker',
  templateUrl: './concrete-strength-picker.component.html',
  styleUrls: ['./concrete-strength-picker.component.scss']
})
export class ConcreteStrengthPickerComponent implements OnInit {
  @Output() onConcreteStrengthSet = new EventEmitter<any>();

  @Input() concreteStrength: string;
  concreteStrengths = [];



  constructor(private concreteStrengthService: ConcreteStrengthService) {
  }

  ngOnInit(): void {
   
    this.concreteStrengthService.get().then((concreteStrengths) => {
      this.concreteStrengths = concreteStrengths;
      console.log("loading: " + this.concreteStrength);
      console.log(concreteStrengths);
      var concreteStrength = this.concreteStrengths.find(a => a.name == this.concreteStrength);
      if (concreteStrength == null || concreteStrength == undefined) {
        var i = {
          archived: false,
          concreteStrengthId: (this.concreteStrength.length + 1),
          name: this.concreteStrength
        }
        this.concreteStrengths.push(i);
      }



      if (this.concreteStrength != null)
        this.concreteStrengthChanged();
    });
  }


  concreteStrengthChanged() {
    var concreteStrength = this.concreteStrengths.find(a => a.name == this.concreteStrength);
    if (concreteStrength != null)
      this.onConcreteStrengthSet.emit(concreteStrength.name);
    else {

      this.onConcreteStrengthSet.emit(this.concreteStrength);

    }

  }

  createNew() {
    console.log(this.concreteStrength);
    var concreteStrength = this.concreteStrengths.find(a => a.name == this.concreteStrength);
    if (concreteStrength == null || concreteStrength == undefined) {
      var i = {
        archived: false,
        concreteStrengthId: (this.concreteStrength.length + 1),
        name: this.concreteStrength
      }
      this.concreteStrengths.push(i);
    }
  }
}
