import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TechnicianService {
    constructor(private http: HttpClient) {
    }

    public get(id: string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + "technician/get?id=" + id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public getTable(id: string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + "technician/getTable").subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }


    public add(technician): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(technician);
            this.http.post<any>(environment.apiUrl + 'technician/post', technician).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public update(technician): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.put<any>(environment.apiUrl + 'technician/put', technician).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
