<div class="content">
    <button class="btn btn-light btn-back" (click)="back()">Back</button>

    <div class="form-groups">
        <div class="form-group">
            <label>Accounts</label>
            <app-account-picker (onAccountIdSet)="accountId = $event; accountChanged();"
                [existingAccountId]="accountId">
            </app-account-picker>
        </div>

        <div class="form-group">
            <label>Projects</label>
            <app-project-picker (onProjectSet)="setProject($event)" [accountId]="accountId"
                [existingProjectId]="projectId">
            </app-project-picker>
        </div>

        <!-- <div class="form-group">
            <label>PO #</label>
            <input type="text" class="form-control po" [(ngModel)]="poNumber" />
        </div> -->

        <!-- <div class="form-group">
            <label>Service Product</label>
            <app-service-products-picker [departmentId]="departmentId" [existingServiceProductId]="serviceProductId"
                (onServiceProductSet)="setServiceProduct($event)">
            </app-service-products-picker>
        </div> -->

        <div class="form-group">
            <label>Activated</label>
            <input type="checkbox" [(ngModel)]="activated" />
        </div>
    </div>

    <div class="roles-test-block buffer-bottom">
        <div class="customer-roles-block">
            <h3>Customer & Roles</h3>
            <div class="customer-roles">
                <div class="form-group">
                    <label>Customer</label>
                    <app-customer-picker [accountId]="accountId" (onCustomerSet)="customer = $event;">
                    </app-customer-picker>
                </div>

                <div class="form-group">
                    <label>Role</label>
                    <app-project-role-picker (onRoleSet)="role = $event;">
                    </app-project-role-picker>
                </div>

                <div class="form-group">
                    <label>Primary</label>
                    <input type="checkbox" [(ngModel)]="isPrimary" style="display:flex;width:10%"/>
                </div>
                    
                <button class="add-btn" (click)="addCustomerRole()">Add</button>
            </div>

            <table *ngIf="customerRoles.length > 0">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Role</th>
                        <th>Is Primary</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let customerRole of customerRoles">
                        <td>{{ customerRole.customer.name}}</td>
                        <td>{{ customerRole.projectRole.description}}</td>
                        <td>{{ customerRole.isPrimary ? 'Y' : 'N' }}</td>
                        <td><button class="add-btn" (click)="removeCustomerRole(customerRole)">Remove</button></td>
                    </tr>
                </tbody>
            </table>
        </div>  
        
        <div class="customer-roles-block">
            <h3>Test Types</h3>
            <div class="customer-roles">
                <div class="form-group">
                    <label>Test Type</label>
                    <app-report-type-picker [existingReportTypeId]="reportTypeId" (onSet)="setReportType($event)"></app-report-type-picker>
                  </div>

                <div class="form-group">
                    <label>PO Number</label>
                   <input type="text" class="form-control"  [(ngModel)]="poNumber" />
                </div>
                    
                <button class="add-btn" (click)="addTestType()">Add</button>
            </div>

            <table *ngIf="testTypes.length > 0">
                <thead>
                    <tr>
                        <th>Test Type</th>
                        <th>PO Number</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let type of testTypes">
                        <td>{{ type.reportType.reportTypeName}}</td>
                        <td>{{ type.poNumber}}</td>
                        <td><button class="add-btn" (click)="removeTestType(type)">Remove</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="test-roles-block">
            <h3>Tests</h3>
            <div class="test-roles" *ngIf="serviceProduct != null">
                <div class="form-group">
                    <label>Test</label>
                    <!-- <app-test-picker [serviceProductId]="serviceProduct.serviceProductId" (onTestSet)="setTest($event)">
                    </app-test-picker> -->
                    <app-quote-test-picker
                        [projectId]="projectId"
                         [serviceProductId]="serviceProduct.serviceProductId"
                         [reportTypes]="testTypes"
                        (onTestSet)="setTest($event)">
                    </app-quote-test-picker>
                </div>

                <div class="form-group" *ngIf="test">
                    <label>Rate</label>
                    <input type="number" class="form-control po" readonly='readonly' [(ngModel)]="test.quotePrice" />
                </div>

                <button class="add-btn" (click)="addTest()">Add</button>
            </div>
            <table *ngIf="tests.length > 0">
                <thead>
                    <tr>
                        <th>Test</th>
                        <th>Rate</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let test of tests">
                        <td>{{ test.testVariantName }}</td>
                        <td>{{ test.quotePrice | currency: 'EUR' }}</td>
                        <td><button class="add-btn" (click)="removeTest(test)">Remove</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <!-- <div class="form-groups notes">
        <div class="form-group">
            <label>Notes</label>
            <textarea name="notes" class="form-control" [(ngModel)]="notes"></textarea>
        </div>
    </div> -->

    <button class="btn btn-success btn-save buffer-top" (click)="save()">Save</button>
</div>