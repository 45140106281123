import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule } from 'primeng/calendar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectsComponent } from './pages/administration/projects/projects.component';
import { ProjectService } from './services/project.service';
import { HttpClientModule } from '@angular/common/http';
import { AccountService } from './services/account.service';
import { AccountPickerComponent } from './components/pickers/account-picker/account-picker.component';
import { ProjectPickerComponent } from './components/pickers/projects/project-picker/project-picker.component';
import { CustomerPickerComponent } from './components/pickers/customer-picker/customer-picker.component';
import { CustomerService } from './services/customer.service';
import { TestPickerComponent } from './components/pickers/test-picker/test-picker.component';
import { TestService } from './services/test.service';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { InvoiceService } from './services/invoice.service';
import { ProjectRolePickerComponent } from './components/pickers/projects/project-role-picker/project-role-picker.component';
import { ServiceProductsService } from './services/service-products.service';
import { ReportTypeService } from './services/report-type.service';
import { ServiceProductsPickerComponent } from './components/pickers/service-products-picker/service-products-picker.component';
import { ReportTypePickerComponent } from './components/pickers/report-type-picker/report-type-picker.component';
import { AuthService } from './services/auth.service';
import { LoginComponent } from './pages/login/login.component';
import { QuoteTestPickerComponent } from './components/pickers/quote-test-picker/quote-test-picker.component';
import { QuoteService } from './services/quote.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ProjectManageComponent } from './pages/administration/projects/project-manage/project-manage.component';
import { JobService } from './services/job.service';
import { SchedulerComponent } from './pages/administration/scheduler/scheduler.component';
import { SchedulerManageComponent } from './pages/administration/scheduler/scheduler-manage/scheduler-manage.component';
import { TechniciansComponent } from './pages/administration/technicians/technicians.component';
import { TechnicianManageComponent } from './pages/administration/technicians/technician-manage/technician-manage.component';
import { TechnicianService } from './services/technician.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { TechnicianPickerComponent } from './components/pickers/technician-picker/technician-picker.component'
import { ConcreteStrengthPickerComponent } from './components/pickers/concrete-strength-picker/concrete-strength-picker.component'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TestTypesComponent } from './pages/administration/test-types/test-types.component';
import { TestTypeManageComponent } from './pages/administration/test-types/test-type-manage/test-type-manage.component';
import { PlateSizePickerComponent } from './components/pickers/testtypes/plate-size-picker/plate-size-picker.component';
import { PlateTestService } from './services/plate-test.service';
import { PlateLoadSizePickerComponent } from './components/pickers/testtypes/plate-load-size-picker/plate-load-size-picker.component';
import { PlateLoadPickerComponent } from './components/pickers/testtypes/plate-load-picker/plate-load-picker.component';
import { PlateBearingReportsComponent } from './pages/reports/plate-bearing-reports/plate-bearing-reports.component';
import { ReportsManageReviewComponent } from './pages/reports/plate-bearing-reports/review/review.component';
import { ReportableService } from './services/reportable.service';
import { IssueService } from './services/issue.service';
import { WebusersComponent } from './pages/administration/webusers/webusers.component';
import { WebusersManageComponent } from './pages/administration/webusers/webusers-manage/webusers-manage.component';
import { WebUserService } from './services/webuser.service';
import { UserProfilePickerComponent } from './components/pickers/user-profile-picker/user-profile-picker.component';
import { ProblematicJobsComponent } from './pages/administration/problematic-jobs/problematic-jobs.component';
import { ReviewComponent } from './pages/administration/review/review.component';
import { ReviewManageComponent } from './pages/administration/review/review-manage/review-manage.component';
import { JobPlateTestService } from './services/job-plate-test.service';
import { SaveFilterService } from './services/save-filter.service';
/*import { ReviewManageReviewComponent } from './pages/administration/review/review-manage/review/review.component';*/
import { ForceCalculatorService } from './services/force-calculator.service';
import {DialogModule} from 'primeng/dialog';
import { ReactionLoadService } from './services/reaction-load.service';
import { MaterialService } from './services/material.service';
import { BasketComponent } from './components/basket/basket.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { ConcretePourReportsComponent } from './pages/reports/concrete-pour-reports/concrete-pour-reports.component';
import { ConcretePourReportsManageReviewComponent } from './pages/reports/concrete-pour-reports/review/concrete-pour-review.component';
import { ConcretePourService } from './services/concrete-pour.service';
import { ConcreteStrengthService } from './services/concrete-strength.service';
import { PlateBearingManageReviewComponent } from './pages/administration/review/review-manage/plate-bearing-review/plate-bearing-review.component';
import { ConcretePourManageReviewComponent } from './pages/administration/review/review-manage/concrete-pour-review/concrete-pour-review.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProjectsComponent,
    AccountPickerComponent,
    ProjectPickerComponent,
    CustomerPickerComponent,
    TestPickerComponent,
    InvoicesComponent,
    ProjectRolePickerComponent,
    ServiceProductsPickerComponent,
    ReportTypePickerComponent,
    LoginComponent,
    QuoteTestPickerComponent,
    ProjectManageComponent,
    SchedulerComponent,
    SchedulerManageComponent,
    TechniciansComponent,
    TechnicianManageComponent,
    TechnicianPickerComponent,
    ConcreteStrengthPickerComponent,
    TestTypesComponent,
    TestTypeManageComponent,
    PlateSizePickerComponent,
    PlateLoadSizePickerComponent,
    PlateLoadPickerComponent,
    PlateBearingReportsComponent,
    ConcretePourReportsComponent,
    ConcretePourReportsManageReviewComponent,
    ReportsManageReviewComponent,
    WebusersComponent,
    WebusersManageComponent,
    UserProfilePickerComponent,
    ReviewComponent,
    ProblematicJobsComponent,
    ReviewManageComponent,
    BasketComponent,
    PlateBearingManageReviewComponent,
    ConcretePourManageReviewComponent
  ],  
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    CalendarModule,
    NgxDatatableModule,
    SnotifyModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    DialogModule,
    ConfirmDialogModule
  ],
  providers: [
    ProjectService,
    AccountService,
    CustomerService,
    TestService,
    InvoiceService,
    ServiceProductsService,
    ReportTypeService,
    AuthService,
    QuoteService,
    JobService,
    TechnicianService,
    PlateTestService,
    ReportableService,
    IssueService,
    WebUserService,
    JobPlateTestService,
    SaveFilterService,
    ForceCalculatorService,
    ConcretePourService,
    ReactionLoadService,
    ConcreteStrengthService,
    CookieService,
    ConfirmationService,
    MaterialService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
