export class BasketItem {
  id: string;
  title: string;
  details: string;
  tag: string;
  report_Type_Id: number;
  company_Id: string;
  company_Name: string;
  location_Id: string;
  location_Name: string;
  serial_Num: string;
  description: string;
  sub_Location: string;
  status: string;
  engineer: string;
  inspected_Date: string;
  next_Inspection_Date: string;
  defects: string;
  repair: string;
  identification: string;
  job_No: string;
}
