import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ReportTypeService } from '../../../services/report-type.service';

@Component({
  selector: 'app-report-type-picker',
  templateUrl: './report-type-picker.component.html',
  styleUrls: ['./report-type-picker.component.scss']
})
export class ReportTypePickerComponent implements OnInit, OnChanges {
  @Input() existingReportTypeId: number;
  @Output() onSet = new EventEmitter<any>();

  reportTypeId: number;
  reportTypes = [];

  constructor(private reportTypeService: ReportTypeService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.existingReportTypeId.currentValue != null && changes.existingReportTypeId.currentValue == 0) {
      this.reportTypeService.get().then((data) => {this.reportTypes = data;});
    }
    if (changes.existingReportTypeId.currentValue != null && changes.existingReportTypeId.currentValue == 0) {
      this.reportTypeId = this.existingReportTypeId;
    }
  }

  reportTypeChanged() {
    var type = this.reportTypes.find(a => a.reportTypeId == this.reportTypeId);
    this.onSet.emit(type);
  }

}
