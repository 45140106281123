<div class="btn-content">
    <a [routerLink]="['/project/manage']" class="btn btn-success">Add New</a>
</div>

<div class="filters-content">
  <div class="filter-row">
    <div class="filter">
      <label>Project</label>
      <input type="text" [(ngModel)]="filter.project" />
    </div>

    <div class="filter">
      <label>Account</label>
      <input type="text" [(ngModel)]="filter.account" />
    </div>

    <div class="filter" *ngIf="false">
      <label>Purchase Order</label>
      <input type="text" [(ngModel)]="filter.purchaseOrder" />
    </div>

    <div class="search-btn">
      <input type="button" class="btn btn-success" value="Search" (click)="loadTable()"/>
    </div>
  </div>
</div>

<div class="content">
    <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
        [rowHeight]="'auto'" [limit]="10">

        <ngx-datatable-column name="Project" prop="projectName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Account" prop="accountName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo" *ngIf="false">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Service Product" prop="serviceProductName">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div>{{ value }}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Option">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/scheduler/manage', row.id]" [queryParams]="{reissue: 1}">Issue Job</a>
                <!-- <a *ngIf="value" href="javascript:void(0)" (click)="toggleActivated(row)">Deactivate</a>
                <a *ngIf="!value" href="javascript:void(0)" (click)="toggleActivated(row)">Activate</a> -->
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Option">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a  [routerLink]="['/project/manage', row.id]">Edit</a>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>