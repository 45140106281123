import { concretepourrecord } from "./concretepourrecord";
import { technician } from "./technician";

export class concretepour {
  accountName: string;
  archived: boolean;
  concretePours: concretepourrecord[];
  createdAt: string;
  id: string;
  job;
  jobId: string;
  pour_Element: string;
  projectName: string;
  purchaseOrderNo: string;
  report_No: number;
  technician: technician;
  userId: string;
  technician_Name: string;
  reason_For_ReIssue: string;
  remarks: string;
}
