import { Component, OnInit } from '@angular/core';
import { PlateTestService } from 'src/app/services/plate-test.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from "file-saver";
import { JobPlateTestService } from 'src/app/services/job-plate-test.service';
import {BasketItem} from '../../../components/basket/basket-item'
import {BasketService} from '../../../components/basket/basket.service'
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SaveFilterService } from 'src/app/services/save-filter.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-plate-bearing-reports',
  templateUrl: './plate-bearing-reports.component.html',
  styleUrls: ['./plate-bearing-reports.component.scss']
})
export class PlateBearingReportsComponent implements OnInit {
  displayDialog: boolean = false;
  rep = {
    id:"",
    projectName:"",
    accountName:"",
    purchaseOrderNo:"",
    technician:"",
    createdAt:"",
  };
  adminPIN:"";
  rows = [];
  filter = {
    project: "",
    account: "",
    purchaseOrder: "",
    startDate: moment().add(-7, 'days').toISOString(),
    endDate: moment().toISOString(),
  }


  constructor(private authService: AuthService, private saveFilterService:SaveFilterService, private jobPlateTestService: JobPlateTestService, private reportableService: ReportableService, 
    private basketService:BasketService, private router: Router) {}

  ngOnInit(): void {
    var savedFilter = this.saveFilterService.getFilters(this.authService.currentUser.value.id, 'plate-bearing-reports');
    if(savedFilter != null){
      this.filter = savedFilter;
      //this.filter.endDate = moment().toISOString()
    }
      
    this.loadTable();
  }

  loadTable() {
    //user_Id: string, page_Name: string, filters: any
    this.saveFilterService.saveFilters(this.authService.currentUser.value.id, 'plate-bearing-reports', this.filter);
    this.jobPlateTestService.getReportTable(this.filter.project, this.filter.account, this.filter.purchaseOrder, this.filter.startDate, this.filter.endDate).then((data) => {
      this.rows = data;
    });
  }

  downloadReport(id) {
    this.reportableService.getPlateBearing(id).then((data) => {
      importedSaveAs(data, "PlateBearing.pdf");
    })
  }

  
  archiveReport(rep) {
    console.log(rep);
      if(confirm("Are you sure you wish to archive this report?")){
        this.jobPlateTestService.archive(rep.id).then((data) => {
          this.loadTable();
        })
      }
  }

  editReport(rep) {
    this.rep = rep;
    console.log(this.rep);
    this.displayDialog = true;

  }
//   {
//     "id": "0eb7046f-0640-4c92-821b-6169287a3d90",
//     "projectName": "New Trinity Hire Depot Wexford",
//     "accountName": "CLDC",
//     "purchaseOrderNo": "N/A",
//     "technician": "Enda Quinlan",
//     "createdAt": "2022-05-30T09:20:36.2215489"
// }
  confirm(){
    this.jobPlateTestService.checkAdminPass(this.adminPIN).then((data) => {
          console.log(this.rep);
          this.displayDialog = false;
          this.router.navigate(['reports/platebearing/manage', this.rep.id]);
    })

   
  }

  cancel() {
    this.displayDialog = false;

  }

  addAllToBasket(){
    var items = this.rows;
    for(var i = 0;i< items.length; i++){
      var item = items[i];
      this.addToBasket(item);
    
    }
  }
  addToBasket(item) {
    let basketItem = new BasketItem();
    console.log(item)
    basketItem.id = item.id;
    basketItem.details = item.accountName;
    basketItem.title = item.projectName;
    basketItem.report_Type_Id = 1;
    basketItem.job_No = item.technician;

    console.log(basketItem);

    this.basketService.add(basketItem);
    item.added = true;
  }

  removeFromBasket(item) {
    var basketItem = this.basketService.getItem(item.id);
    console.log(basketItem);
     this.basketService.remove(basketItem);
     item.added = false;
  }

  refreshRows() {
    var basketItems = this.basketService.getDatasource();
    var items = this.rows;
    for(var i = 0;i< items.length; i++){
      var item = items[i];
      var added = false;
      for(var b = 0;b< basketItems.length; b++){
        var basketItem = basketItems[b];
        if(item.id == basketItem.id)
        added = true;
      }
      item.added = added;
    }
  }
}
