import { Component, OnInit } from '@angular/core';
import { TechnicianService } from 'src/app/services/technician.service';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss']
})
export class TechniciansComponent implements OnInit {

  rows;

  constructor(private technicianService: TechnicianService) { }

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    // if (this.search == "")
    //   return;

    this.technicianService.getTable().then((rows) => {
      this.rows = rows;
      console.log(this.rows);
    });
  }
}
