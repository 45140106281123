import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-project-picker',
  templateUrl: './project-picker.component.html',
  styleUrls: ['./project-picker.component.scss']
})
export class ProjectPickerComponent implements OnInit, OnChanges {
  @Input() existingProjectId: number;
  @Input() accountId: number;
  @Output() onProjectIdSet = new EventEmitter<number>();
  @Output() onProjectSet = new EventEmitter<any>();

  projectId: number;

  allProjects = [];
  projects = [];
  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.get().then((projects) => {
      this.allProjects = projects;
      this.projects = projects;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateProjects();

    if (changes.existingProjectId != null && changes.existingProjectId.currentValue) {
      this.projectId = this.existingProjectId;
    }
  }

  updateProjects(): void {
    this.projects = this.allProjects.filter(a => a.accountId == this.accountId);
  }

  projectChanged() {
    this.onProjectIdSet.emit(this.projectId);
    var project = this.projects.find(a => a.projectId == this.projectId);
    this.onProjectSet.emit(project);
  }
}
