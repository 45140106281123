import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ProjectService {
    constructor(private http: HttpClient) {
    }

    public getTable(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'project/getTable').subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public get(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'project/get').subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
    public getServiceProduct(id): Promise<any> {
        return new Promise((resolve, reject) => {
          this.http.get<any>(environment.apiUrl + 'project/getServiceProduct?id=' + id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public getRoles(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'project/getroles').subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
