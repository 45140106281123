<div class="content">
    <button class="btn btn-light btn-back" (click)="back()">Back</button>

    <div class="form-groups-column buffer-bottom">
        <div class="form-group">
            <label>Firstname</label>
            <input type="text" class="form-control" [(ngModel)]="firstname" />
        </div>

        <div class="form-group">
            <label>Lastname</label>
            <input type="text" class="form-control" [(ngModel)]="lastname" />
        </div>

        <div class="form-group">
            <label>Email</label>
            <input type="email" class="form-control" [(ngModel)]="email" />
        </div>

        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" [(ngModel)]="password" />
        </div>

        <div class="form-group">
            <label>Confirm Password</label>
            <input type="password" class="form-control" [(ngModel)]="password2" />
        </div>

        <div class="form-group">
            <label>BHP User Profile</label>
            <app-user-profile-picker [userId]="userId" (onProfileSet)="setUserProfile($event)"></app-user-profile-picker>
        </div>

        <div class="form-group">
            <label>Signature</label>
            <label>Please ensure signature is 320px x 85px for optimal aesthetics.</label>
            <input type="file" id="imagePicker" (change)="changeListener($event)" accept="image/*" />
        </div>

        <div class="form-group">
            <label>Title</label>
            <input type="title" class="form-control" [(ngModel)]="title" />
        </div>
    </div>

    <div class="action-buttons">
      <button class="btn btn-success btn-save" (click)="save()">Save</button>
      <div class="spacer"></div>
      <button *ngIf="id != null" class="btn btn-danger btn-save" (click)="delete()">Deactivate</button>
    </div>
</div>