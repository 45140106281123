<p-dialog header="Assign Test For Invoicing " [(visible)]="displayDialog" [modal]="true"
  [contentStyle]="{'min-height': '100px', 'overflow-y': 'auto', 'width': '435px'}" [closable]="false">
  <div class="row">
    <div class="column-max">
      <b><p>Arrived - {{arrivalTime}}, Departed - {{departureTime}}</p></b>
    </div>
  </div>
  <div *ngFor="let jobTest of jobTests">
    <div class="row">
      <div class="column-max">
        <p>{{ jobTest.testVariantName }} - {{ jobTest.quotePrice | currency: 'EUR' }}</p>
      </div>
      <div class="column-min">
        <input type="checkbox" name="selectedForInvoicing" class="selectedForInvoicing"
          [(ngModel)]="jobTest.selectedForInvoicing" />
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" (click)="setTest()" label="Save" class="btn btn-primary">Save</button>
    <button type="button" (click)="cancel()" label="Cancel" class="btn btn-primary">Cancel</button>
  </p-footer>
</p-dialog>

<div class="content">
  <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [rowHeight]="'auto'" [limit]="10">

    <ngx-datatable-column name="Project" prop="projectName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Account" prop="accountName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Service Product" prop="serviceProductName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Arrival Time" prop="arrivalTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Departure Time" prop="departureTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="# Of Jobs" prop="noOfJobs">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="preview(row)" *ngIf="row.hasPlateTests">Download</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="issue(row)"
          *ngIf="row.hasSelectedJobTestForInvoicing && row.hasPlateTests">Get Consignment</a>
        <a href="javascript:void(0)" (click)="assignTest(row)"
          *ngIf="!row.hasSelectedJobTestForInvoicing && row.hasPlateTests">Assign Test For Invoicing</a>
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="['/reviews/manage', row.id]" *ngIf="row.hasPlateTests">Edit</a>
      </ng-template>
    </ngx-datatable-column> -->

    <!-- <ngx-datatable-column name="Option">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a href="javascript:void(0)" (click)="generateSamples(row)">Generate Samples</a>
            </ng-template>
        </ngx-datatable-column> -->

  </ngx-datatable>
</div>