<ng-select [items]="accounts" autofocus bindLabel="label" bindValue="accountId" [virtualScroll]="true"
    [(ngModel)]="accountId" (ngModelChange)="accountChanged()" placeholder="Please Select" [selectOnTab]="true">
    <ng-template ng-header-tmp>
        <div class="ng-select-header">
            <div>Code</div>
            <div>Customer</div>
        </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-index="index">
        <div>
            <div>{{ item.accountCode }} - {{ item.companyName }}</div>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="ng-select-option">
            <div>{{ item.accountCode }}</div>
            <div>{{ item.companyName }}</div>
        </div>
    </ng-template>
</ng-select>