<button class="btn btn-light btn-back buffer-top-left" (click)="back()">Back</button>

<div class="details-container" *ngIf="test">
  <div class="form-group">
    <label>Customer Reference</label>
    <input type="text" class="form-control po" [(ngModel)]="test.customerReference"  />
  </div>

  <div class="form-group">
    <label>Location Reference</label>
    <input type="text" class="form-control po" [(ngModel)]="test.locationReference"  />
  </div>

  <div class="form-group">
    <label>Tested Date</label>
    <div class="date-wrapper">
      <div class="date-wrapper-wrapper">
        <input matInput [matDatepicker]="picker" class="form-control" [(ngModel)]="test.createdAt" style="width:90%">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #picker></mat-datepicker>
  </div>
  </div>
  
  <div class="form-group">
    <label>Type of reaction Load</label>
    <ng-select [items]="reactionLoads" autofocus bindLabel="load" bindValue="load" [virtualScroll]="true"
      placeholder="Please Select" [(ngModel)]="test.typeOfReactionLoad" (ngModelChange)="reactionLoadChanged()">
    </ng-select>
    <input *ngIf="showReactionLoadOther" type="text" class="form-control po" [(ngModel)]="otherReactionLoad"  />
  </div>

  <div class="form-group">
    <label>Material</label>
    <ng-select [items]="materials" autofocus bindLabel="description" bindValue="materialId" [virtualScroll]="true"
      placeholder="Please Select" [(ngModel)]="test.materialId">
    </ng-select>
  </div>
</div>

<div class="main-container" *ngIf="test">
  <div class="load-container">
    <div class="load-block" *ngFor="let load of test.jobPlateTestLoads">
      <input class="load" type="number" [value]="load.load" readonly />
      <input type="text" [(ngModel)]="load.load1" />
      <input type="text" [(ngModel)]="load.load2" />
      <input type="text" [(ngModel)]="load.load3" />
      <input class="avg" [(ngModel)]="load.avg" readonly />
    </div>

    <div class="totals">
      <div><label>CBR: {{ test.cbr }}</label></div>
      <div><label>K: {{ test.k }}</label></div>
    </div>

    <div class="btn-row">
      <button type="button" class="btn btn-success buffer-right" (click)="recalculate(true)">Recalculate</button>
      <button type="button" class="btn btn-success buffer-left" (click)="update()">Update</button>
      <!-- <button class="buffer-right add-btn" (click)="recalculate(true)">Recalculate</button>
            <button class="buffer-left add-btn" (click)="update()">Update</button> -->
    </div>
  </div>
  <div class="remarks-container">
    <div class="form-group">
      <label>Remarks:</label>
      <input type="text" class="form-control input-remarks" [(ngModel)]="test.remarks" />
    </div>
  </div>
</div>