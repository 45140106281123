import { Component, OnInit } from '@angular/core';
import { validate } from 'json-schema';
import { JobService } from 'src/app/services/job.service';
import { TechnicianService } from 'src/app/services/technician.service';
import { Router, Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-technician-manage',
  templateUrl: './technician-manage.component.html',
  styleUrls: ['./technician-manage.component.scss']
})
export class TechnicianManageComponent implements OnInit {

  id: string = null;
  firstname: string = "";
  lastname: string = "";
  pin: string = "";
  pin2: string = "";
  expired: boolean = false;
  archived: boolean = false;


  constructor(private technicianService: TechnicianService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramsMap) => {
      var id = paramsMap.get("id");
      if (id != null) {
        this.technicianService.get(id).then((technician) => {
          this.id = technician.id;
          this.firstname = technician.firstname;
          this.lastname = technician.lastname;
          this.pin = technician.pin;
          this.pin2 = technician.pin;
          this.expired = technician.expired;
        });
      }
    });
  }

  back() {
    this.router.navigate(['technicians']);
  }

  validate() {
    if (this.firstname == null || this.firstname == "") {
      alert("Enter firstname");
      return false;
    }

    if (this.lastname == null || this.lastname == "") {
      alert("Enter lastname");
      return false;
    }

    if (this.pin == null || this.pin == "") {
      alert("Enter pin");
      return false;
    }

    if (this.pin2 == null || this.pin2 == "") {
      alert("Enter pin2");
      return false;
    }

    if (this.pin != this.pin2) {
      alert("Pins do not match");
      return false;
    }


    return true;
  }

  async save() {
    if (!this.validate()) {
      return;
    }

    var technician = {
      id: this.id,
      firstname: this.firstname,
      lastname: this.lastname,
      pin: this.pin,
      expired: this.expired,
      archived: this.archived
    };

    if (this.id == null)
      await this.technicianService.add(technician);
    else
      await this.technicianService.update(technician);

    this.router.navigate(['technicians']);
  }


}
