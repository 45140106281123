<div class="basket-container">
  <div class="basket card">
    <div>
      <div style="cursor:pointer;" (click)="toggle()" class="row">
        <div class="col-md-8">
          <i class="fa fa-shopping-basket"></i>
          Basket
        </div>
        <div class="col-md-4" style="float:right">
          {{ basketItems.length }} items
        </div>
      </div>
    </div>

    <div class="items-layout" [ngClass]="{'show': isOpen}">
      <div class="row buffer-bottom">
        <div class="col-md-4">
          <button style="width: 100%;" (click)="downloadBatch()" class="btn btn-success">Download</button>
        </div>
        <div class="col-md-4">
          <button style="width: 100%;" (click)="clear()" class="btn btn-danger">Clear</button>
        </div>
      </div>

      <div class="items-list-layout">
        <div *ngFor="let item of basketItems">
          <div class="item-container">
            <h5>{{ item.title }} - {{ item.details }} - {{ item.job_No }}</h5>
            <button class="btn btn-danger" (click)="remove(item)">X</button>
          </div>
          <hr />
        </div>
      </div>
    </div>
    
  </div>
</div>
