<div class="login-block">
    <form (ngSubmit)="onLogin()">
        <div class="form-group">
            <label>Email</label>
            <input type="email" class="form-control" [(ngModel)]="email" name="email" />
        </div>

        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" [(ngModel)]="password" name="password" />
        </div>

        <input type="submit" class="add-btn" value="Login" />

        <p *ngIf="invalid" style="color: red; margin-top: 10px; font-weight: bold;">Invalid Email/Password.</p>
    </form>
</div>