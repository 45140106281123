<div class="content" *ngIf="job != null">
  <!-- <div>{{ job.purchaseOrderNo }}</div> -->
  
  <div class="form-group">
    <label>Test Type</label>
    <ng-select [items]="reportTypes" autofocus bindLabel="reportTypeName" bindValue="reportTypeId" [virtualScroll]="true"
      placeholder="Please Select" [(ngModel)]="reportTypeId" (ngModelChange)="reportTypeChanged()" [selectOnTab]="true">
    </ng-select>
  </div>

  <div class="form-group">
    <label>PO #</label>
    <input name="po" type="text" class="form-control po" [(ngModel)]="po" />
  </div>

  <div class="form-group">
    <label>Scheduled Date</label>
    <div class="date-wrapper">
      <input matInput [matDatepicker]="picker" class="form-control" [(ngModel)]="scheduledDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </div>

  <div class="form-group">
    <label>Arrival Time (optional)</label>
    <div class="date-wrapper">
      <ngx-timepicker-field [(ngModel)]="arrivalTime"></ngx-timepicker-field>
    </div>
  </div>

  <div class="form-group">
    <label>Departure Time (optional)</label>
    <div class="date-wrapper">
      <ngx-timepicker-field [(ngModel)]="departureTime"></ngx-timepicker-field>
    </div>
  </div>

  <div class="form-group" *ngIf="!job.reportTypes.includes(2)">
    <label>Technician</label>
    <app-technician-picker (onTechnicianSet)="setTechnician($event)" [technicianId]="job.technicianId">
    </app-technician-picker>
  </div>

  <div class="form-groups notes">
    <div class="form-group">
      <label>Notes</label>
      <textarea name="notes" class="form-control" [(ngModel)]="notes"></textarea>
    </div>
  </div>


  <div *ngIf="job.reportTypes.includes(2)" style="margin-bottom:1vh">


    <!--<div class="form-groups">
      <div class="form-group">
        <label>Pour Element</label>
        <textarea name="pour" class="form-control" [(ngModel)]="pour_Element"></textarea>
      </div>
    </div>
    <div class="form-groups">
      <div class="form-group">
        <label>Weather Condition</label>
        <ng-select [items]="weather" autofocus bindLabel="name" bindValue="weatherId" [virtualScroll]="true"
                   placeholder="Please Select" [(ngModel)]="weatherId" [selectOnTab]="true">
        </ng-select>
      </div>
    </div>
    <div class="form-groups">
      <div class="form-group">
        <label>Compaction Method</label>
        <ng-select [items]="compaction" autofocus bindLabel="name" bindValue="compactionId" [virtualScroll]="true"
                   placeholder="Please Select" [(ngModel)]="compactionId" [selectOnTab]="true">
        </ng-select>
      </div>
    </div>-->

    <div class="form-groups">
      <div class="form-group">
        <label>Technician</label>
        <div style="display:flex;flex-direction:row">
          <app-technician-picker (onTechnicianSet)="setTempTechnician($event)" style="width:95%"></app-technician-picker>
          <!--<ng-select [items]="technicians" autofocus bindLabel="name" bindValue="id" [virtualScroll]="true"
             placeholder="Please Select" [selectOnTab]="true">
        </ng-select>-->
          <button type="button" class="add-btn" (click)="addTechnician()" style="width:5%">+</button>
        </div>
      </div>
    </div>
    <div class="form-groups">
      <div class="form-group">
        <hr />
        <div *ngFor="let tech of selectedTechnicians">
          <div class="item-container">
            <h5>{{ tech.name }}</h5>
            <button type="button" class="btn btn-danger" (click)="removeTechnician(tech)">X</button>
          </div>
        </div>
      </div>
      <hr />

    </div>

  </div>
      <!-- <div class="ngx-timepicker-field-example">
        <ngx-timepicker-field [defaultTime]="'11:11 am'"></ngx-timepicker-field>
    </div> -->
      <button style="margin-bottom:20vh" class="add-btn" (click)="save()">Schedule</button>
    </div>
