import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import { IssueService } from 'src/app/services/issue.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from "file-saver";
import { Router } from '@angular/router';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  rows = [];
  displayDialog: boolean = false;
  jobTests = [];

  arrivalTime = '';
  departureTime ='';
  filter = {
    project: "",
    account: "",
    purchaseOrder: "",
    reportTypeId: 0
  }

  constructor(private jobService: JobService, private issueService: IssueService, private reportableService: ReportableService
    , private router: Router) {
  }

  ngOnInit(): void {
    this.loadTable();

  }

  preview(job): void {
    if (job.hasPlateTests) {
      this.reportableService.getPlateBearingPreview(job.id).then((data) => {
        importedSaveAs(data, "PlateBearingPreview.pdf");
      });
    }
    if (job.hasConcreteTests) {
      this.reportableService.getConcretePourPreview(job.id).then((data) => {
        importedSaveAs(data, "ConcretePourPreview.pdf");
      });
    }
 
  }

  issue(job): void {
    console.log(job);
    this.issueService.issue(job.id, job.selectedJobTestForInvoicingId).then((result) => {
      this.loadTable();
    });
  }

  assignTest(job): void {

   
    this.jobService.getJobTests(job).then((data) => {
      this.jobTests = data;
      console.log(job);
      console.log(data);
      if (this.jobTests.length > 1) {
        this.arrivalTime = job.arrivalTime;
        this.departureTime = job.departureTime;
        this.displayDialog = true;
      } else {
        // There's only one test assigned so use this
        let jobTest = this.jobTests[0];
        this.assignTestToJobForInvoicing(jobTest);
      }
    });
  }

  setTest() {
    if (this.jobTests.filter(t => t.selectedForInvoicing == true).length == 0) {
      alert("Please select a test to be used for invoicing purposes");
      return;
    }

    if (this.jobTests[0].ReportTypeId <= 1 && this.jobTests.filter(t => t.selectedForInvoicing == true).length > 1) {
      alert("Please select just one test to be used for invoicing purposes");
      return;
    }

    if(this.jobTests[0].ReportTypeId <= 1){
      let jobTest = this.jobTests.find(u => u.selectedForInvoicing == true);
      this.assignTestToJobForInvoicing(jobTest);
    } else{
      for(var i =0; i < this.jobTests.length; i++){
        var test = this.jobTests[i];
        if(test.selectedForInvoicing == true || test.testVariantId == 1658 ){
          this.assignTestToJobForInvoicing(test);
        }
      }
    }

  }

  assignTestToJobForInvoicing(jobTest) {
    this.jobService.assignTestForInvoicing(jobTest.id, jobTest.chosenHours).then((data) => {
      if (this.displayDialog) {
        this.displayDialog = false;
      }
      this.loadTable();
    });
  }

  cancel() {
    this.displayDialog = false;
  }

  loadTable(): void {
    console.log(this.filter.reportTypeId);
    this.jobService.getTable(this.filter.project, this.filter.account, this.filter.purchaseOrder, true, true, null, this.filter.reportTypeId).then((jobs) => {
      this.rows = jobs;
      console.log(this.rows);
    });
  }

  generateSamples(job): void {
    this.issueService.generateSamples(job.id).then((result) => {
      console.log(result);
    });
  }
  setReportType(event) {
    if (event != undefined)
      this.filter.reportTypeId = event.reportTypeId;
    else
      this.filter.reportTypeId = 0;
  }
  editItem(row, type) {
    //this.router.navigate(['administration/depots/manage'], { queryParams: { page: this.page.pageNumber } });
    this.router.navigate(['reviews/manage'], { queryParams: { id: row.id, jobType: type } });
  }
}
