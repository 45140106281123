<p-dialog header="Assign Test For Invoicing " [(visible)]="displayDialog" [modal]="true"
          [contentStyle]="{'min-height': '100px', 'overflow-y': 'auto', 'width': '435px'}" [closable]="false">
  <div class="row">
    <div class="column-max">
      <b><p>Arrived - {{arrivalTime}}, Departed - {{departureTime}}</p></b>
    </div>
  </div>
  <div *ngFor="let jobTest of jobTests">
    <div class="row">
      <div class="column-max">
        <p>{{ jobTest.testVariantName }} - {{ jobTest.quotePrice | currency: 'EUR' }}</p>
      </div>
      <div class="column-min">
        <input type="checkbox" name="selectedForInvoicing" class="selectedForInvoicing"
               [(ngModel)]="jobTest.selectedForInvoicing" *ngIf="jobTest.testVariantId != 1658" />

               <select [(ngModel)]="jobTest.chosenHours" *ngIf="jobTest.testVariantId == 1658">
                <option value="undefined">Please Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
              </select>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" (click)="setTest()" label="Save" class="btn btn-primary">Save</button>
    <button type="button" (click)="cancel()" label="Cancel" class="btn btn-primary">Cancel</button>
  </p-footer>
</p-dialog>

<div class="filters-content">
  <div class="filter-row">
    <div style=" width: 90%; margin-right: 1%; display: flex; flex-direction: column; justify-content: center;">
      <label>Test Type</label>
      <app-report-type-picker  [existingReportTypeId]="filter.reportTypeId" (onSet)="setReportType($event)"></app-report-type-picker>
    </div>

    <div class="search-btn">
      <input type="button" class="btn btn-success" value="Search" (click)="loadTable()" />
    </div>
  </div>
</div>

<div class="content">


  <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                 [rowHeight]="'auto'" [limit]="10">

    <ngx-datatable-column name="Project" prop="projectName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Account" prop="accountName">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Purchase Order #" prop="purchaseOrderNo">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Test" prop="report_Type">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Date of Test" prop="created_Date">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value | date: 'dd/MM/yyyy' }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Arrival Time" prop="arrivalTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Departure Time" prop="departureTime">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="# Of Jobs" prop="noOfJobs">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div>{{ value }}</div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="preview(row)" *ngIf="row.hasPlateTests || row.hasConcreteTests">Preview</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <a href="javascript:void(0)" (click)="issue(row)"
           *ngIf="row.hasSelectedJobTestForInvoicing && (row.hasPlateTests || row.hasConcreteTests)">Approve</a>
        <a href="javascript:void(0)" (click)="assignTest(row)"
           *ngIf="!row.hasSelectedJobTestForInvoicing && (row.hasPlateTests || row.hasConcreteTests)">Assign Test For Invoicing</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Option">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.hasPlateTests" (click)="editItem(row,1)" class="edit-option">Edit</span>
        <span *ngIf="!row.hasPlateTests" (click)="editItem(row,2)" class="edit-option">Edit</span>
        <!--<a [routerLink]="['/reviews/manage', row.id, '' ,1]" *ngIf="row.hasPlateTests">Edit</a>
        <a [routerLink]="['/reviews/manage', row.id, '' ,2]" *ngIf="!row.hasPlateTests">Edit</a>-->
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column name="Option">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a href="javascript:void(0)" (click)="generateSamples(row)">Generate Samples</a>
            </ng-template>
        </ngx-datatable-column> -->

  </ngx-datatable>
</div>
