import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SortDirection } from '@swimlane/ngx-datatable';

type Calculation = {
    force: number,
    lastAvg: number,
    postAvg: number,
    cbr: number,
    k: number
    maxLoadValue: number,
    maxLoadReading: number
}

@Injectable()
export class ForceCalculatorService {

    calculation: Calculation;

    constructor() {
    }

    public calculate(loads, loadIncrement, plateLoadSize) {
        this.calculation = {
            force: 0,
            lastAvg: 0,
            postAvg: 0,
            cbr: 0,
            k: 0,
            maxLoadValue: 0,
            maxLoadReading: 0
        };

        loads = loads.sort((a, b) => (a.position - b.position));

        loads = this.createAverages(loads);

        this.findAverages(loads, loadIncrement);

        this.calculateCBRK(plateLoadSize);

        return this.calculation;
    }

    private createAverages(loads) {
        console.log(loads);
    
        for (let load of loads) {
            var l = [];
            if (load.load1 > 0)
                l.push(load.load1);
            if (load.load2 > 0)
                l.push(load.load2);
            if (load.load3 > 0)
                l.push(load.load1);
            load.avg = this.round(this.round(((((load.load1 *100 ) + (load.load2 *100 )  + (load.load3*100 )  ) / 100) / l.length),3),2)
        }
        console.log("After AVG");
        console.log(loads);
        return loads;
    }

    private findAverages(loads, loadIncrement) {
        // We need to find a value that equals to 1.25
        // If no value is found we need to find the value thats closest to it
        // but must be < 1.25
        var lastAvg = 0;
        var minimumForce = 0;
        var idx = -1;
        for (let load of loads) {
            idx++;

            if (load.avg > 1.25) {
                this.calculation.maxLoadValue = load.load;
                this.calculation.maxLoadReading = load.avg;
                loadIncrement = this.calculation.maxLoadValue - minimumForce;
                if(isNaN(loadIncrement) && ! isNaN(minimumForce))
                    loadIncrement = minimumForce
                else if(isNaN(loadIncrement) && isNaN(minimumForce))
                    loadIncrement = 0;
                break;
            }else if(idx > 0 && load.load == 0){
                var prevLoad = this.GetPreviousMaxLoad(idx,loads);
                this.calculation.maxLoadValue = prevLoad.load;
                this.calculation.maxLoadReading = prevLoad.avg;
                loadIncrement = this.calculation.maxLoadValue - minimumForce;
                break;
            }

            minimumForce = load.load;
            lastAvg = load.avg;

            if (idx == loads.length - 1) {
                this.calculation.maxLoadValue = load.load;
                this.calculation.maxLoadReading = load.avg;
            }
        }

        this.calculation.lastAvg = lastAvg;
        this.calculation.postAvg = loads[idx].avg;
        var force = this.calculateForce(minimumForce, this.calculation.lastAvg, this.calculation.postAvg, loadIncrement);
        this.calculation.force = force;
    }

    private GetPreviousMaxLoad(index: number, loads){
        var prevLoad;
        let idx = -1;
        let idx2 = -1;
        for (let load of loads) {
            idx ++;
            if (idx < index) {
               if(prevLoad > load.load){
                for (let innerLoad of loads) {
                    idx2++;
                    var required = idx - 1;
                    if (idx2 == required) {
                        return innerLoad;
                    }
                }
         
               }
               prevLoad = load.load;
            }
        }
    }

    private calculateForce(minimumForce, lastAvg, postAvg, loadIncrement) {
        if(isNaN(lastAvg))
            lastAvg = 0;  
            
        if(isNaN(postAvg))
            postAvg = 0;

        var lastDiff = this.round(this.round((1.25 - lastAvg),3),2);
        var postDiff = this.round(this.round((postAvg - lastAvg),3),2);

        var val = 0; //Changed to match the tablet side code - KC 20/01/2022
        if (loadIncrement <= 100)
            val = (lastDiff / postDiff) * loadIncrement;
        else
            val = (lastDiff / postDiff) * loadIncrement;
         
            if(isNaN(val))
                val = 0;
        // var force = parseInt((minimumForce + val).toString());
        var force = Math.round(minimumForce + val);
        return force;
    }

    private calculateCBRK(plateLoadSize) {
        var force = this.calculation.force;

        // If the max encountered value is >= 1.25 then get the force from the calculation above
        // Otherwise MaxReading / MaxLoad = A
        // Then 1.25 / A = B
        if (this.calculation.maxLoadReading < 1.25 && this.calculation.maxLoadReading > 0) {
            var val = (this.calculation.maxLoadReading / this.calculation.maxLoadValue);
            val = Math.round((1.25 / val));
            val = this.round(val,4);
            force = parseInt(val.toString());
            this.calculation.force = force;
        }

        if (plateLoadSize == 450) {
            this.calculation.cbr = Math.round((6.1 * Math.pow(10, -8) * Math.pow((496 * force), 1.733)));
            this.calculation.k = Math.round((0.62 * ((force / 1.25) * Math.pow(10, 3))));
        } else {
            this.calculation.cbr = Math.round((6.1 * Math.pow(10, -8) * Math.pow((652 * force), 1.733)));
            this.calculation.k = Math.round((0.815 * ((force / 1.25) * Math.pow(10, 3))));
        }
    }



    private round(n,dp) {
        var h = +('1'.padEnd(dp + 1,'0'));
        return Math.round(n*h)/h;
    }
}
