import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';

@Injectable()
export class WebUserService {
    constructor(private http: HttpClient, private snotifyService: SnotifyService) {
    }

    public get(id: string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + "useraccount/get?id=" + id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public getTable(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + "useraccount/gettable").subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }


    public add(user): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(user);
            this.http.post<any>(environment.apiUrl + 'useraccount/post', user).subscribe(data => {
                resolve(data);
                //this.snotifyService.success('Successfully added web user');
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public update(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.put<any>(environment.apiUrl + 'useraccount/put', user).subscribe(data => {
                resolve(data);
                //this.snotifyService.success('Successfully updated web user');
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public delete(id): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.delete<any>(environment.apiUrl + 'useraccount/delete?id=' + id).subscribe(data => {
              resolve(data);
              //this.snotifyService.success('Successfully added web user');
          }, error => {
              console.log(error);
              reject();
          });
      });
  }

    public getProfiles(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(environment.apiUrl + 'useraccount/getuserprofiles').subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}
