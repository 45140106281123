import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { SnotifyService, ToastDefaults } from 'ng-snotify';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BHPWebApp';
  isAuthed: boolean = false;

  constructor(private authService: AuthService,private snotifyService: SnotifyService) {
    this.authService.currentUser.subscribe((currentUser) => {
      this.isAuthed = this.authService.isAuthed();
    });

    this.snotifyService.config = ToastDefaults;
  }

  logout() {
    this.authService.logout();
  }
}
