import { Component, OnInit } from '@angular/core';
import { PlateTestService } from 'src/app/services/plate-test.service';

@Component({
  selector: 'app-test-types',
  templateUrl: './test-types.component.html',
  styleUrls: ['./test-types.component.scss']
})
export class TestTypesComponent implements OnInit {

  rows;
  constructor(private plateTestService: PlateTestService) { }

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    this.plateTestService.getTable().then((rows) => {
      this.rows = rows;
    });
  }
}
