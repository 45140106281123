import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class SaveFilterService {
    constructor(private cookieService: CookieService) {
    }

    getFilters(user_Id: string, page_Name: string) {
        var cookie = this.cookieService.get(user_Id + page_Name); //GET FILTERS SAVED IN THE SESSION
        console.log(cookie);
        if (cookie == null || cookie == "")
          return null;
        return this.parseFilters(cookie);
      }

      saveFilters(user_Id: string, page_Name: string, filters: any) {
        var filter = new Filter(); //SAVE THE FILTERS WHEN GETTING DATA
        filter.id = user_Id + page_Name; //GUID + Page ie reports/jobs
        filter.filters = JSON.stringify(filters);
        this.cookieService.set(filter.id, filter.filters);
      }
    
      parseFilters(filtersAsJson: any) {
        var filters = JSON.parse(filtersAsJson);
        console.log(filters);
        return filters;
      }
}

export class Filter {
    id: string;
    filters: string;
  }
  